/**
 * @generated SignedSource<<9f32ed61bafd5314920f0c610f00c1b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type BusinessPaymentStatus = "CONFIRMED" | "DELAYED" | "EXPIRED" | "PAID" | "PENDING" | "REFUNDED" | "UNDERPAID" | "%future added value";
export type BusinessProcessStatus = "ERROR" | "INITIATED" | "PROCESSED" | "UNPROCESSED" | "%future added value";
export type CryptoCurrencies = "BTC" | "ETH" | "LTC" | "USDC" | "%future added value";
export type PaymentInformationSubscription$variables = {
  id: string;
};
export type PaymentInformationSubscription$data = {
  readonly onBusinessChargeUpdate: {
    readonly node: {
      readonly paymentStatus: BusinessPaymentStatus;
      readonly processStatus: BusinessProcessStatus;
      readonly transaction: {
        readonly amount: string;
        readonly confirmations: number;
        readonly currency: CryptoCurrencies;
        readonly id: string;
        readonly isInternal: boolean;
      } | null;
    } | null;
  } | null;
};
export type PaymentInformationSubscription = {
  variables: PaymentInformationSubscription$variables;
  response: PaymentInformationSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStatus",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processStatus",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessTransaction",
  "kind": "LinkedField",
  "name": "transaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInternal",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentInformationSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BusinessUpdateChargePayload",
        "kind": "LinkedField",
        "name": "onBusinessChargeUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessCharge",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentInformationSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BusinessUpdateChargePayload",
        "kind": "LinkedField",
        "name": "onBusinessChargeUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessCharge",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6dd1ba067b650bc14bc0ec4352dc76d",
    "id": null,
    "metadata": {},
    "name": "PaymentInformationSubscription",
    "operationKind": "subscription",
    "text": "subscription PaymentInformationSubscription(\n  $id: ID!\n) {\n  onBusinessChargeUpdate(id: $id) {\n    node {\n      paymentStatus\n      processStatus\n      transaction {\n        amount\n        confirmations\n        currency\n        id\n        isInternal\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "06dccd2d28cbd33ea59c853960633254";

export default node;
