import type {FC} from 'react';
import {useMemo, useCallback} from 'react';
import {API} from 'aws-amplify';
import type {
  PaymentInformationUserQuery,
  PaymentInformationUserQuery$data,
  BusinessPaymentStatus
} from 'components/__generated__/PaymentInformationUserQuery.graphql';
import type {PaymentInformationGenerateReceiptMutation$data} from 'components/__generated__/PaymentInformationGenerateReceiptMutation.graphql';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import SuspenseFallback from 'components/SuspenseFallback';
import {AuthContext} from 'libs/utils';
import {useParams} from 'react-router-dom';
import {QueryRenderer, requestSubscription} from 'react-relay';
import {FaMoneyBillWave as MoneyIcon} from 'react-icons/fa';
import {HiExternalLink as ExternalLinkIcon} from 'react-icons/hi';
import {VscCloudDownload as DownloadIcon} from 'react-icons/vsc';
import {MdContentCopy as CopyIcon} from 'react-icons/md';
import graphql from 'babel-plugin-relay/macro';
import {
  Box,
  Heading,
  HStack,
  Text,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  VStack,
  Badge,
  Button,
  ButtonGroup,
  Center,
  Link,
  useClipboard,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import {useMutation, useRelayEnvironment} from 'react-relay';
import {pascalCase} from 'change-case';
import {useForm} from 'react-hook-form';
import {formatCurrency} from 'utils/strings';
import {CryptoCurrencies, Errors, NetworkFees} from '@criptan/types';
import {cryptoRegex} from 'config';
import BigNumber from 'bignumber.js';

const query = graphql`
  query PaymentInformationUserQuery($token: String!, $id: ID!) {
    getBusinessCharge(token: $token, id: $id) {
      id
      expiresAt
      description
      createdAt
      confirmedAt
      paidAt
      metadata
      paymentStatus
      processStatus
      processedAt
      refundPaidAt
      refundRequestedAt
      userId
      checkoutUrl
      products {
        title
        price
        quantity
        image
        description
      }
      pricing {
        fiat {
          amount
          currency
        }
        LTC {
          address
          amount
          currency
        }
        ETH {
          address
          amount
          currency
        }
        BTC {
          address
          amount
          currency
        }
      }
      transaction {
        amount
        confirmations
        currency
        id
        isInternal
      }
    }
  }
`;

const subscription = graphql`
  subscription PaymentInformationSubscription($id: ID!) {
    onBusinessChargeUpdate(id: $id) {
      node {
        paymentStatus
        processStatus
        transaction {
          amount
          confirmations
          currency
          id
          isInternal
        }
      }
    }
  }
`;

const generateReceiptMutation = graphql`
  mutation PaymentInformationGenerateReceiptMutation(
    $token: String!
    $id: ID!
    $lng: String
  ) {
    businessGenerateReceipt(input: {token: $token, id: $id, lng: $lng}) {
      node {
        url
      }
    }
  }
`;

const refundMutation = graphql`
  mutation PaymentInformationRefundMutation(
    $token: String!
    $id: String!
    $addresses: BusinessRefundAddresInput!
    $otpCode: String
  ) {
    businessRefund(
      input: {token: $token, id: $id, addresses: $addresses, otpCode: $otpCode}
    ) {
      node {
        id
      }
    }
  }
`;

type IParams = {data: PaymentInformationUserQuery$data['getBusinessCharge']};

type RefundForm = {
  address: string;
  amount: string;
  otpCode?: string;
};
type StatusColors = 'green' | 'yellow' | 'blue' | 'gray';

const statusSchema = {
  CONFIRMED: 'green',
  PAID: 'green',
  PENDING: 'blue',
  DELAYED: 'yellow',
  UNDERPAID: 'yellow',
  REFUNDED: 'green',
  EXPIRED: 'gray',
  '%future added value': 'gray'
};

const CRYPTO_DECIMALS = 8;

// @todo temporal fix until USDC is added to Payments
type PaymentCryptoCurrencies = Exclude<CryptoCurrencies, 'USDC'>;

interface MutationError extends Error {
  errors?: {
    message: string;
  }[];
}

const PaymentInformation: FC<IParams> = ({data}) => {
  const {t, i18n} = useTranslation();
  const [url, setUrl] = useState<string>('');
  const [refund, setRefund] = useState<{
    transactionAmount: string;
    fee: string;
    amountToRefund: string;
  }>({
    transactionAmount: '0',
    fee: '0',
    amountToRefund: '0'
  });
  const [currency, setCurrency] = useState<PaymentCryptoCurrencies>('BTC');
  const [colorSchema, setColorSchema] = useState<StatusColors>('gray');
  const auth = useContext(AuthContext);
  const toast = useToast();
  const [locale] = i18n.languages;
  const {
    transaction,
    pricing,
    createdAt,
    id,
    paymentStatus,
    processStatus,
    checkoutUrl
  } = data as Exclude<IParams['data'], null>;
  const chargeAddress = checkoutUrl as string;
  const {onCopy} = useClipboard(chargeAddress);
  const {onCopy: onCopyLink} = useClipboard(url);
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm<RefundForm>();
  const [isOpenRefundModal, setIsOpenRefundModal] = useState<boolean>(false);
  const [generateReceiptMutationCommit, generateReceiptMutationIsInFlight] =
    useMutation(generateReceiptMutation);
  const [refundMutationCommit, refundMutationIsInFlight] =
    useMutation(refundMutation);
  const environment = useRelayEnvironment();

  const onSubmit = async (data: RefundForm) => {
    if (!auth) throw new Error("Auth isn't initialized");
    const token = await auth?.getToken();
    refundMutationCommit({
      variables: {
        token,
        id,
        addresses: {
          BTC: {
            currency: 'BTC',
            address: data.address,
            amount: refund.amountToRefund
          }
        },
        otpCode: data.otpCode ?? ''
      },
      onError: (err) => {
        const errors = (err as MutationError).errors;
        let code = 'errors.refund';
        let message = '';
        if (errors && errors.length > 0) {
          try {
            const error = JSON.parse(errors[0].message);
            if (error.code === Errors.requireOtp.code) {
              setDisplayOtpCode(true);
              return;
            }
            if (error.code) {
              code = `errors.${error.code}`;
              message = error.message ?? err.message;
            }
          } catch (err) {
            console.log('no json');
          }
        }
        toast({
          title: t(code, message),
          status: 'error',
          isClosable: true
        });
        onRefundClose();
      },
      onCompleted: (response) => {
        if (response) {
          toast({
            title: t('refundSuccess'),
            status: 'success',
            isClosable: true
          });
          onRefundClose();
        }
      }
    });
  };

  const copyAddress = () => {
    onCopy();
    toast({
      title: t('paymentInformationCopyToClipboard'),
      status: 'success',
      isClosable: true
    });
  };

  const copyReceiptLink = () => {
    onCopyLink();
    toast({
      title: t('paymentInformationPdfCopyToClipboard'),
      status: 'success',
      isClosable: true
    });
  };

  const onGeneratePdf = async () => {
    if (!auth) throw new Error("Auth isn't initialized");
    const token = await auth?.getToken();
    generateReceiptMutationCommit({
      variables: {
        token,
        id: id,
        lng: locale
      },
      onCompleted: (response, error) => {
        if (error) {
          toast({
            title: t('errors.generateReceiptError'),
            status: 'error',
            isClosable: true
          });
          return;
        }
        const {businessGenerateReceipt} =
          response as PaymentInformationGenerateReceiptMutation$data;
        if (businessGenerateReceipt.node?.url) {
          const pdfUrl = businessGenerateReceipt.node.url as string;
          setUrl(pdfUrl);
          window.open(pdfUrl, '_blank');
        }
      }
    });
  };

  const isRefundable = useMemo(
    () =>
      (['UNDERPAID', 'DELAYED'] as BusinessPaymentStatus[]).includes(
        paymentStatus
      ) && processStatus === 'PROCESSED',
    [paymentStatus, processStatus]
  );
  const hasReceipt = useMemo(
    () =>
      (
        [
          'CONFIRMED',
          'UNDERPAID',
          'DELAYED',
          'REFUNDED',
          'PAID',
          'CONFIRMED'
        ] as BusinessPaymentStatus[]
      ).includes(paymentStatus),
    [paymentStatus]
  );

  const handleValidateWalletAddress = useCallback(
    (value: string) => new RegExp(cryptoRegex[currency]).test(value),
    [currency]
  );

  useEffect(() => {
    if (data?.id) {
      const variables = {id: data.id};
      requestSubscription(environment, {
        subscription,
        variables
      });
    }
  }, [data?.id, environment]);

  useEffect(
    () => setColorSchema(statusSchema[paymentStatus] as StatusColors),
    [paymentStatus]
  );

  useEffect(() => {
    if (transaction?.currency) {
      setCurrency(transaction.currency as PaymentCryptoCurrencies);
      setRefund({
        transactionAmount: transaction.amount,
        amountToRefund: transaction.amount,
        fee: '0'
      });
    }
  }, [transaction?.amount, transaction?.currency]);

  const [networkFees, setNetworkFees] = useState<NetworkFees | undefined>();
  const [displayOtpCode, setDisplayOtpCode] = useState(false);

  const onRefundPress = async () => {
    setIsOpenRefundModal(true);
    try {
      const result = await API.graphql({
        query: `query {
          getNetworkFees {
            BTC
            ETH
            LTC            
          }
        } `
      });
      // @ts-expect-error it is not mapping correctly the returned type
      const fees = result?.data?.getNetworkFees as NetworkFees;
      setNetworkFees(fees);
      const coinFee = fees[transaction!.currency as PaymentCryptoCurrencies];
      const transactionAmount = new BigNumber(transaction!.amount);
      const refundAmount = transactionAmount.minus(coinFee).toString();
      setRefund({
        transactionAmount: transactionAmount.toString(),
        fee: coinFee.toString(),
        amountToRefund: refundAmount.toString()
      });
    } catch (err) {
      toast({
        // @ts-expect-error
        title: t(`error.${err.code ?? 'refundError'}`),
        status: 'error',
        isClosable: true
      });
    }
  };

  const onRefundClose = () => {
    setDisplayOtpCode(false);
    setIsOpenRefundModal(false);
    reset();
  };
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('paymentInformationPageTitle', {id})}</title>
      </Helmet>
      <Box as="article">
        <VStack
          as="header"
          align="stretch"
          spacing={2}
          border="thin"
          rounded="lg"
        >
          <HStack justifyContent="space-between" px={2} py={4}>
            <Heading
              as="h3"
              fontSize="md"
              textColor="gray.900"
              fontWeight="semibold"
              justifyContent="space-between"
            >
              <Icon as={MoneyIcon} mx={1} />
              {t('paymentInformationSummaryTitle')}
            </Heading>

            <HStack as="div" align="center">
              <Text textColor="gray.600" fontWeight="light" fontSize="sm">
                {id}
              </Text>
              <Icon
                as={CopyIcon}
                mx={1}
                boxSize={{base: 3, sm: 4}}
                onClick={copyAddress}
              />
            </HStack>
          </HStack>
          <StatGroup
            // @ts-expect-error: bad tipyngs
            size="sm"
            bgColor="whitesmoke"
            rounded="lg"
            py={4}
          >
            <Stat textAlign="center">
              <StatLabel>{t('paymentInformationSummaryDate')}</StatLabel>
              <StatNumber textColor="gray.600" fontSize="sm">
                {new Intl.DateTimeFormat(locale).format(new Date(createdAt))}
              </StatNumber>
            </Stat>
            <Stat textAlign="center">
              <StatLabel>{t('paymentInformationSummaryFiatAmount')}</StatLabel>
              <StatNumber textColor="gray.600" fontSize="sm">
                {formatCurrency(
                  locale,
                  pricing[currency].currency,
                  pricing[currency].amount,
                  CRYPTO_DECIMALS
                )}
              </StatNumber>
            </Stat>
            <Stat textAlign="center">
              <StatLabel>
                {t('paymentInformationSummaryCryptoAmount')}
              </StatLabel>
              {transaction ? (
                <StatNumber textColor="gray.600" fontSize="sm">
                  {formatCurrency(
                    locale,
                    transaction.currency,
                    transaction.amount,
                    CRYPTO_DECIMALS
                  )}
                </StatNumber>
              ) : (
                t('paymentInformationSummaryNotPaid')
              )}
            </Stat>
            <Stat textAlign="center">
              <StatLabel>{t('paymentInformationSummaryStatus')}</StatLabel>
              <StatNumber textAlign="center" textColor="gray.600" fontSize="sm">
                <Badge fontSize="xs" variant="solid" colorScheme={colorSchema}>
                  {t(`transactionTableStatus${pascalCase(paymentStatus)}`)}
                </Badge>
              </StatNumber>
            </Stat>
          </StatGroup>
        </VStack>

        <Center p={4}>
          <ButtonGroup>
            <Button isDisabled={!isRefundable} onClick={onRefundPress}>
              {t('paymentInformationRefundPayment')}
            </Button>
            <Link href={chargeAddress} isExternal>
              <Button as="span" colorScheme="brand">
                {t('paymentInformationGoToGateway')}
                <Icon as={ExternalLinkIcon} mx={2} />
              </Button>
            </Link>
            <Button
              colorScheme="brand"
              isDisabled={!hasReceipt}
              isLoading={generateReceiptMutationIsInFlight}
              onClick={onGeneratePdf}
            >
              {t('paymentInformationGeneratePdf')}
              <Icon as={DownloadIcon} mx={2} />
            </Button>
            <Center display={url ? 'initial' : 'none'}>
              <Button as="span" onClick={copyReceiptLink}>
                <Icon as={CopyIcon} mx={1} boxSize={{base: 3, sm: 4}} />
                {t('paymentInformationCopyPdfLink')}
              </Button>
            </Center>
          </ButtonGroup>
        </Center>
        <Modal isOpen={isOpenRefundModal} onClose={onRefundClose}>
          <ModalOverlay />
          <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {t('paymentInformationRequestRefundHeader')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isOpenRefundModal && (
                <VStack spacing={4} w="full" alignItems="stretch">
                  <Text>
                    {t('paymentInformationRequestRefundBodyAmount', {
                      refundAmount: formatCurrency(
                        locale,
                        transaction!.currency,
                        refund.transactionAmount,
                        CRYPTO_DECIMALS
                      )
                    })}
                  </Text>
                  <Text>
                    {t('paymentInformationRequestRefundBodyNetworkFee', {
                      refundFee: formatCurrency(
                        locale,
                        transaction!.currency,
                        refund.fee,
                        CRYPTO_DECIMALS
                      )
                    })}
                  </Text>
                  <Text>{t('paymentInformationRequestRefundBody')}</Text>
                  <FormControl
                    id="address"
                    mb={2}
                    isRequired
                    isInvalid={!!errors.address}
                  >
                    <FormLabel textColor="gray.900">
                      {t('paymentInformationRequestRefundAddress')}
                    </FormLabel>
                    <Input
                      type="string"
                      focusBorderColor="brand.600"
                      bgColor="whitesmoke"
                      disabled={displayOtpCode}
                      placeholder={t(
                        'paymentInformationRequestRefundAddressPlaceholder'
                      )}
                      {...register('address', {
                        validate: handleValidateWalletAddress
                      })}
                    />
                    {errors.address && (
                      <Text color="red" fontWeight="600">
                        {t('paymentInformationInvalidAddress', {
                          currency: transaction?.currency
                        })}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl
                    id="otpCode"
                    display={displayOtpCode ? '' : 'none'}
                    mb={2}
                  >
                    <FormLabel textColor="gray.900">
                      {t('paymentInformationRequestRefundOtp')}
                    </FormLabel>
                    <Input
                      type="string"
                      focusBorderColor="brand.600"
                      bgColor="whitesmoke"
                      placeholder={t(
                        'paymentInformationRequestRefundOtpPlaceholder'
                      )}
                      {...register('otpCode', {
                        minLength: 6,
                        maxLength: 6
                      })}
                    />
                  </FormControl>
                </VStack>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={
                  !!errors.address ||
                  (displayOtpCode && !!errors.otpCode) ||
                  !networkFees
                }
                colorScheme="brand"
                type="submit"
                isLoading={refundMutationIsInFlight}
              >
                {t(
                  displayOtpCode
                    ? 'paymentInformationRequestRefundConfirmation'
                    : 'paymentInformationRequestRefundRequest'
                )}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

const Component: FC = () => {
  const {id} = useParams<{id: string}>();
  const auth = useContext(AuthContext);
  const [token, setToken] = useState<string | undefined>();
  useEffect(() => {
    const getToken = async () =>
      auth?.getCurrentUser()
        ? setToken(await auth?.getToken())
        : setToken(undefined);
    getToken();
  }, [auth]);
  const environment = useRelayEnvironment();
  if (!auth) throw new Error("Auth hasn't been initialized");

  if (!token) return <SuspenseFallback />;

  return (
    <QueryRenderer<PaymentInformationUserQuery>
      environment={environment}
      query={query}
      variables={{
        token,
        id
      }}
      render={({error, props}) => {
        if (error) {
          throw error;
        }
        if (!props) {
          return <SuspenseFallback />;
        }
        return (
          <PaymentInformation
            data={
              props.getBusinessCharge as Exclude<
                PaymentInformationUserQuery['response']['getBusinessCharge'],
                null
              >
            }
          />
        );
      }}
    />
  );
};

export default Component;
