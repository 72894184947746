import type {FC, ReactElement} from 'react';
import type {FragmentType} from 'types';
import type {Sidebar_businessaccount$data} from 'components/__generated__/Sidebar_businessaccount.graphql';
import type {IconType} from 'react-icons';
import {
  VStack,
  Icon,
  Link,
  HStack,
  Text,
  Image,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup
} from '@chakra-ui/react';
import {Routes} from 'types/enums';
import {FaUserAlt as UserIcon} from 'react-icons/fa';
import {
  MdDashboard as DashboardIcon,
  MdHeadsetMic as HelpIcon
} from 'react-icons/md';
import {GoSettings as SettingsIcon} from 'react-icons/go';
import {BiLogOut as LogOutIcon} from 'react-icons/bi';
import {IoDocumentAttach as DocumentationIcon} from 'react-icons/io5';
import {useContext, useState, useRef} from 'react';
import {Link as RouterLink, useLocation, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AuthContext} from 'libs/utils';
import env from 'env-var';
import graphql from 'babel-plugin-relay/macro';

export const fragment = graphql`
  fragment Sidebar_businessaccount on BusinessAccount {
    branding {
      shopName
      logoUrl
    }
  }
`;

const {documentationSite} = {
  documentationSite: env
    .get('REACT_APP_DOCUMENTATION_URL')
    .required()
    .asString()
};

type RouteWithIcon = {
  destination: string;
  name: string;
  icon: IconType;
  isExternal?: boolean;
};

const routes: RouteWithIcon[] = [
  {
    destination: Routes.PAYMENTS,
    icon: DashboardIcon,
    name: Routes.PAYMENTS
  },
  {
    destination: Routes.SETTINGS,
    name: Routes.SETTINGS,
    icon: SettingsIcon
  },
  {
    destination: `https://${documentationSite}`,
    icon: DocumentationIcon,
    isExternal: true,
    name: 'documentationSite'
  },
  {
    destination: Routes.HELP,
    icon: HelpIcon,
    name: Routes.HELP
  }
];

type Params = {account: FragmentType<Sidebar_businessaccount$data>};

const Component: FC<Params> = ({account}): ReactElement => {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const cancelRef = useRef(null);

  const createNavigation = (link: RouteWithIcon) => {
    if (link.isExternal)
      return (
        <Link
          key={link.destination}
          isExternal
          href={link.destination}
          w="full"
          color={pathname === link.destination ? 'brand.600' : 'gray.900'}
        >
          <HStack>
            <Icon as={link.icon} boxSize={6} />
            <Text fontWeight="semibold">{t(`routes.${link.name}`)}</Text>
          </HStack>
        </Link>
      );
    else
      return (
        <Link
          key={link.destination}
          as={RouterLink}
          to={link.destination}
          w="full"
          color={pathname === link.destination ? 'brand.600' : 'gray.900'}
        >
          <HStack>
            <Icon as={link.icon} boxSize={6} />
            <Text fontWeight="semibold">{t(`routes.${link.destination}`)}</Text>
          </HStack>
        </Link>
      );
  };
  const links = routes.map(createNavigation);
  const closeAlert = () => setIsAlertOpen(false);
  const openAlert = () => setIsAlertOpen(true);
  return (
    <>
      <VStack spacing={4}>
        <HStack w="full" alignItems="start">
          {account.branding?.logoUrl ? (
            <Image src={account.branding.logoUrl} boxSize={10} />
          ) : (
            <Icon as={UserIcon} boxSize={10} />
          )}
          <VStack alignItems="stretch">
            <Text
              textTransform="capitalize"
              textColor="gray.500"
              fontWeight="medium"
              fontSize="sm"
            >
              {account.branding?.shopName}
            </Text>
          </VStack>
        </HStack>
        <VStack as="nav" w="full">
          {links}
          <Button
            alignSelf="start"
            variant="link"
            onClick={openAlert}
            color="gray.900"
          >
            <HStack>
              <Icon as={LogOutIcon} boxSize={6} />
              <Text fontWeight="semibold">{t('sidebarLogOut')}</Text>
            </HStack>
          </Button>
        </VStack>
      </VStack>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('sidebarCancelTitleLogOut')}
            </AlertDialogHeader>
            <AlertDialogBody>{t('sidebarCancelMessage')}</AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button ref={cancelRef} onClick={closeAlert}>
                  {t('sidebarCancelLogOut')}
                </Button>
                <Button
                  colorScheme="brand"
                  onClick={() => {
                    history.push(Routes.LOGIN);
                    auth?.signOut();
                  }}
                >
                  {t('sidebarConfirmLogOut')}
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Component;
