/**
 * @generated SignedSource<<1624d13706d8e78d553ec6052bc83d8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfigureAccountMutation$variables = {
  token: string;
  id: string;
  logoUrl?: string | null;
  shopName: string;
};
export type ConfigureAccountMutation$data = {
  readonly businessUpdateAccount: {
    readonly node: {
      readonly branding: {
        readonly shopName: string | null;
        readonly logoUrl: string | null;
      } | null;
    } | null;
  };
};
export type ConfigureAccountMutation = {
  variables: ConfigureAccountMutation$variables;
  response: ConfigureAccountMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "logoUrl"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shopName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v4 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "logoUrl",
            "variableName": "logoUrl"
          },
          {
            "kind": "Variable",
            "name": "shopName",
            "variableName": "shopName"
          }
        ],
        "kind": "ObjectValue",
        "name": "branding"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessAccountBranding",
  "kind": "LinkedField",
  "name": "branding",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shopName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BusinessUpdateAccountPayload",
        "kind": "LinkedField",
        "name": "businessUpdateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessAccount",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConfigureAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BusinessUpdateAccountPayload",
        "kind": "LinkedField",
        "name": "businessUpdateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessAccount",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c29c46ea4c7f93d9bb37137d5e1e89e",
    "id": null,
    "metadata": {},
    "name": "ConfigureAccountMutation",
    "operationKind": "mutation",
    "text": "mutation ConfigureAccountMutation(\n  $token: String!\n  $id: ID!\n  $logoUrl: String\n  $shopName: String!\n) {\n  businessUpdateAccount(input: {token: $token, id: $id, branding: {shopName: $shopName, logoUrl: $logoUrl}}) {\n    node {\n      branding {\n        shopName\n        logoUrl\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c508b73acdeee241e2866b2b5712205";

export default node;
