import {SimpleGrid, GridItem} from '@chakra-ui/react';
import type {ReactElement} from 'react';
import {useContext, useState, useEffect} from 'react';
import {QueryRenderer, useRelayEnvironment} from 'react-relay';
import SuspenseFallback from 'components/SuspenseFallback';
import {MainUserQuery} from 'components/__generated__/MainUserQuery.graphql';
import Dashboard from 'components/Dashboard';
import CreatePayment from 'components/CreatePayment';
import PaymentInformation from 'components/PaymentInformation';
import Help from 'components/Help';
import Settings from 'components/Settings';
import Sidebar from 'components/Sidebar';
import graphql from 'babel-plugin-relay/macro';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {Routes} from 'types/enums';
import {AuthContext} from 'libs/utils';

const query = graphql`
  query MainUserQuery($token: String!) {
    getBusinessAccount(token: $token) {
      accountStatus
      apiKeyId
      userId
      notificationUrl
      cryptoHoldingPercentage
      id
      branding {
        accentColor
        bgColor
        logoUrl
        shopName
      }
      deletedAt
    }
    getBusinessCharges(token: $token) {
      edges {
        node {
          id
          expiresAt
          description
          createdAt
          confirmedAt
          paidAt
          metadata
          paymentStatus
          processStatus
          processedAt
          refundPaidAt
          refundRequestedAt
          userId
          products {
            title
            price
            quantity
            image
            description
          }
          pricing {
            fiat {
              amount
              currency
            }
            LTC {
              address
              amount
              currency
            }
            ETH {
              address
              amount
              currency
            }
            BTC {
              address
              amount
              currency
            }
          }
          transaction {
            amount
            confirmations
            currency
            id
            isInternal
          }
        }
      }
    }
  }
`;

const Component = (): ReactElement => {
  const auth = useContext(AuthContext);
  const [token, setToken] = useState<string | undefined>();
  const location = useLocation();
  useEffect(() => {
    const getToken = async () =>
      auth?.getCurrentUser()
        ? setToken(await auth?.getToken())
        : setToken(undefined);
    getToken();
  }, [auth]);
  if (!auth) throw new Error("Auth hasn't been initialized");
  const user = auth.getCurrentUser();
  const environment = useRelayEnvironment();
  if (!user)
    return (
      <Redirect
        to={{pathname: Routes.LOGIN, state: {referrer: location.pathname}}}
      />
    );
  if (!token) return <SuspenseFallback />;
  return (
    <QueryRenderer<MainUserQuery>
      environment={environment}
      query={query}
      variables={{
        token
      }}
      render={({error, props, retry}) => {
        if (error) {
          console.log(error);
          throw error;
        }
        if (!props) {
          return <SuspenseFallback />;
        }
        if (props.getBusinessAccount?.accountStatus === 'NOT_CONFIGURED')
          return <Redirect to={Routes.CREATE_ACCOUNT} />;
        return (
          <SimpleGrid columns={4} h="full">
            <GridItem
              colSpan={1}
              display={{base: 'none', md: 'initial'}}
              rowStart={1}
              rowSpan={2}
            >
              <Sidebar
                account={
                  props.getBusinessAccount as Exclude<
                    MainUserQuery['response']['getBusinessAccount'],
                    null
                  >
                }
              />
            </GridItem>
            <GridItem
              colSpan={{base: 4, md: 3}}
              bgColor="white"
              rounded="lg"
              p={8}
              shadow="lg"
              rowStart={1}
              rowSpan={2}
              overflow="auto"
            >
              <Switch>
                <Route exact path={Routes.PAYMENTS}>
                  <Dashboard
                    charges={
                      props.getBusinessCharges as Exclude<
                        MainUserQuery['response']['getBusinessCharges'],
                        null
                      >
                    }
                  />
                </Route>
                <Route exact path={Routes.CREATE_PAYMENT}>
                  <CreatePayment onComplete={retry} />
                </Route>
                <Route path={`${Routes.PAYMENT}/:id`}>
                  <PaymentInformation />
                </Route>
                <Route exact path={Routes.SETTINGS}>
                  <Settings
                    account={
                      props.getBusinessAccount as Exclude<
                        MainUserQuery['response']['getBusinessAccount'],
                        null
                      >
                    }
                  />
                </Route>
                <Route path={`${Routes.HELP}`}>
                  <Help />
                </Route>
              </Switch>
            </GridItem>
          </SimpleGrid>
        );
      }}
    />
  );
};

export default Component;
