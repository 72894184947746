import type {FC, ReactElement} from 'react';
import {
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  Select
} from '@chakra-ui/react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {VscMenu as MenuIcon} from 'react-icons/vsc';
import {MdDashboard as DashboardIcon} from 'react-icons/md';
import {GoSettings as SettingsIcon} from 'react-icons/go';
import {AiOutlineLogout as LogOutIcon} from 'react-icons/ai';
import {FaChevronDown as ChevronDown} from 'react-icons/fa';
import {useContext} from 'react';
import {AuthContext} from 'libs/utils';
import {ReactComponent as Logo} from 'assets/logo.svg';
import {Routes} from 'types/enums';
import {useTranslation} from 'react-i18next';

const languages = ['en', 'es', 'pt', 'fr'];

const Component: FC = (): ReactElement => {
  const {t, i18n} = useTranslation();
  const lang = i18n.language;
  const auth = useContext(AuthContext);
  const history = useHistory();
  // @todo fix bad icon placement!
  return (
    <Flex justify="space-between" pb={8}>
      <RouterLink to={Routes.PAYMENTS}>
        <Icon as={Logo} w={40} h={8} />
      </RouterLink>
      <Select
        maxWidth="3xs"
        onChange={(event) => i18n.changeLanguage(event.target.value)}
        defaultValue={lang?.length > 2 ? lang.split('-')[0] : lang}
        icon={<ChevronDown />}
        iconColor="brand.600"
        focusBorderColor="brand.600"
        backgroundColor="whitesmoke"
      >
        {languages.map((language) => (
          <option key={language} value={language}>
            {t(`languages.${language}`)}
          </option>
        ))}
      </Select>
      <Menu isLazy={true}>
        <MenuButton
          display={{base: 'initial', md: 'none'}}
          as={Button}
          variant="unstyled"
          color="brand.600"
          rightIcon={<MenuIcon />}
        ></MenuButton>
        <MenuList color="brand.600">
          <MenuItem
            as={RouterLink}
            to={Routes.PAYMENTS}
            icon={<DashboardIcon />}
          >
            <Text as="span" color="gray.900">
              {t(`routes.${Routes.PAYMENTS}`)}
            </Text>
          </MenuItem>
          <MenuItem
            as={RouterLink}
            to={Routes.SETTINGS}
            icon={<SettingsIcon />}
          >
            <Text as="span" color="gray.900">
              {t(`routes.${Routes.SETTINGS}`)}
            </Text>
          </MenuItem>
          <MenuItem
            as={Button}
            display={auth?.getCurrentUser() ? 'initial' : 'none'}
            variant="unstyled"
            icon={<LogOutIcon />}
            onClick={() => {
              history.push(Routes.LOGIN);
              auth?.signOut();
            }}
            fontWeight="normal"
          >
            <Text as="span" color="gray.900">
              {t('headerLogOut')}
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Component;
