import {Heading, Icon, Text, Container, Link} from '@chakra-ui/react';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import {ReactComponent as Logo} from 'assets/logo.svg';
import {Routes} from 'types/enums';

const Component: FC = () => {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('notFoundPageTitle')}</title>
      </Helmet>
      <Container centerContent px={4} as="main">
        <Icon as={Logo} w="full" h="4xs" />
        <Heading as="h1" my={2}>
          {t('notFoundTitle')}
        </Heading>
        <Text>{t('notFoundMessage')}</Text>
        <Link
          //@ts-expect-error: wrong types
          to={Routes.DASHBOARD}
        ></Link>
      </Container>
    </>
  );
};

export default Component;
