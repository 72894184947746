/**
 * @generated SignedSource<<655e3c95e806e00e288476847afddbff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SettingsSecretMutation$variables = {
  token: string;
  id: string;
  secret: string;
  notificationUrl: string;
};
export type SettingsSecretMutation$data = {
  readonly businessPutSecret: {
    readonly node: {
      readonly id: string;
    } | null;
  };
};
export type SettingsSecretMutation = {
  variables: SettingsSecretMutation$variables;
  response: SettingsSecretMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "notificationUrl"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "secret"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "notificationUrl",
        "variableName": "notificationUrl"
      },
      {
        "kind": "Variable",
        "name": "secret",
        "variableName": "secret"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessAccount",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsSecretMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BusinessPutSecretPayload",
        "kind": "LinkedField",
        "name": "businessPutSecret",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SettingsSecretMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "BusinessPutSecretPayload",
        "kind": "LinkedField",
        "name": "businessPutSecret",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c02d6bd7e7124daa8e4aa5157a568ba",
    "id": null,
    "metadata": {},
    "name": "SettingsSecretMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsSecretMutation(\n  $token: String!\n  $id: ID!\n  $secret: String!\n  $notificationUrl: String!\n) {\n  businessPutSecret(input: {token: $token, id: $id, secret: $secret, notificationUrl: $notificationUrl}) {\n    node {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa6538a1cfc346486b052d32a89ef8ba";

export default node;
