import type {FC, ReactElement} from 'react';
import {
  Stack,
  Icon,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Container,
  useToast
} from '@chakra-ui/react';
import {BsFillLockFill as LockIcon} from 'react-icons/bs';
import {useForm} from 'react-hook-form';
import {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import {useHistory, useLocation} from 'react-router-dom';
import {Routes} from 'types/enums';
import type {ILocationState} from 'types';
import {ReactComponent as Logo} from 'assets/logo.svg';
import {AuthContext, withError} from 'libs/utils';

interface IFormData {
  password: string;
  username: string;
}

const Component: FC = (): ReactElement => {
  const {t} = useTranslation();
  const auth = useContext(AuthContext);
  const location = useLocation<ILocationState>();
  const history = useHistory();
  const {register, handleSubmit} = useForm<IFormData>();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: IFormData) => {
    const state = location.state ? location.state : undefined;
    if (!auth) throw new Error("Auth hasn't been initialized");
    setIsLoading(true);
    const {error, result} = await withError(
      auth.logUser(data.username, data.password)
    );
    if (!result) {
      toast({
        title: t(`errors.${(error as Error & {code: string}).code}`),
        status: 'error',
        isClosable: true
      });
    } else {
      toast({
        title: t('messages.loggedIn'),
        status: 'success',
        isClosable: true
      });
      history.push(state?.referrer ?? Routes.PAYMENTS);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('loginPageTitle')}</title>
      </Helmet>
      <Container>
        <Stack
          as="form"
          h="full"
          w="full"
          onSubmit={handleSubmit(onSubmit)}
          spacing="4"
          bgColor="white"
          p={8}
          rounded="lg"
          shadow="lg"
        >
          <VStack as="header">
            <Icon as={Logo} w="full" h="4xs" maxW="lg" />
            <Text fontSize="xs">Powered by Criptan</Text>
          </VStack>
          <VStack spacing="2">
            <FormControl isRequired id="username">
              <FormLabel>{t('loginFormUsername')}</FormLabel>
              <Input
                autoComplete="email"
                type="email"
                bgColor="whitesmoke"
                focusBorderColor="brand.600"
                placeholder={t('loginFormUsername')}
                {...register('username')}
              />
            </FormControl>
            <FormControl isRequired id="password">
              <FormLabel>{t('loginFormPassword')}</FormLabel>
              <Input
                autoComplete="password"
                type="password"
                bgColor="whitesmoke"
                focusBorderColor="brand.600"
                placeholder={t('loginFormPassword')}
                {...register('password')}
              />
            </FormControl>
            <Button
              colorScheme="brand"
              type="submit"
              leftIcon={<LockIcon />}
              isLoading={isLoading}
            >
              {t('loginFormSignIn')}
            </Button>
          </VStack>
        </Stack>
      </Container>
    </>
  );
};

export default Component;
