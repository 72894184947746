import type {FC} from 'react';
import {useState, useEffect, useContext} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Header from 'components/Header';
import Login from 'components/Login';
import ConfigureAccount from 'components/ConfigureAccount';
import Main from 'components/Main';
import NotFound from 'components/NotFound';
import {Routes} from 'types/enums';
import {Grid, GridItem, Box} from '@chakra-ui/react';
import {AuthContext} from 'libs/utils';
import SuspenseFallback from './SuspenseFallback';

const Component: FC = () => {
  const [authReady, setAuthReady] = useState(false);
  const auth = useContext(AuthContext);

  useEffect(() => {
    auth?.subscribeToAuthState(setAuthReady);
  }, [auth]);

  if (!authReady) return <SuspenseFallback header />;
  return (
    <Box h="100vh" w="100vw" bgColor="whitesmoke">
      <Grid
        templateRows="2rem min-content repeat(8, 1fr) min-content 2rem"
        templateColumns="2rem repeat(10, 1fr) 2rem"
        maxW="7xl"
        mx="auto"
        h="full"
      >
        <GridItem rowStart={2} rowSpan={1} colStart={2} colSpan={10}>
          <Header />
        </GridItem>
        <GridItem rowStart={3} rowSpan={8} colStart={2} colSpan={10} h="full">
          <Switch>
            <Route exact path={Routes.LOGIN}>
              <Login />
            </Route>
            <Route exact path={Routes.CREATE_ACCOUNT}>
              <ConfigureAccount />
            </Route>
            <Route exact path={Routes.ROOT}>
              <Redirect to={Routes.PAYMENTS} />
            </Route>
            <Route path={Routes.DASHBOARD}>
              <Main />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Component;
