import type {FC, ReactElement} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import {useHistory} from 'react-router-dom';
import {
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  useToast
} from '@chakra-ui/react';
import {BiMailSend as SendIcon} from 'react-icons/bi';
import env from 'env-var';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import {Routes} from 'types/enums';

const {supportDomain} = {
  supportDomain: env.get('REACT_APP_SUPPORT_DOMAIN').required().asString()
};

interface IFormData {
  contactEmail: string;
  contactText: string;
  contactName: string;
}

const Component: FC = (): ReactElement => {
  const {t} = useTranslation();
  const {register, handleSubmit} = useForm<IFormData>();
  const [isInFlight, setIsInFlight] = useState<boolean>(false);
  const toast = useToast();
  const history = useHistory();
  const onSubmit = async (data: IFormData): Promise<void> => {
    setIsInFlight(true);
    const payload = {
      request: {
        requester: {name: data.contactName, email: data.contactEmail},
        subject: 'New help request from CriptanPay',
        comment: {body: data.contactText}
      }
    };

    try {
      await axios.post('/requests.json', payload, {
        baseURL: supportDomain,
        headers: {'Content-Type': 'application/json'}
      });
      toast({
        title: t('messages.createTicketSuccess'),
        status: 'success',
        isClosable: true
      });
      history.push(Routes.PAYMENTS);
    } catch (error) {
      toast({
        title: t('messages.createTicketError'),
        status: 'error',
        isClosable: true
      });
      console.log(error);
    }
    setIsInFlight(false);
    return;
  };
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('helpPageTitle')}</title>
      </Helmet>
      <VStack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl isRequired>
          <FormLabel>{t('helpContactName')}</FormLabel>
          <Input
            bgColor="whitesmoke"
            focusBorderColor="brand.600"
            type="text"
            {...register('contactName')}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t('helpContactEmail')}</FormLabel>
          <Input
            bgColor="whitesmoke"
            focusBorderColor="brand.600"
            type="email"
            {...register('contactEmail')}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t('helpContactQuestion')}</FormLabel>
          <Textarea
            backgroundColor="whitesmoke"
            focusBorderColor="brand.600"
            placeholder={t('helpContactQuestionPlaceholder')}
            {...register('contactText')}
          />
        </FormControl>
        <Button
          colorScheme="brand"
          type="submit"
          leftIcon={<SendIcon />}
          isLoading={isInFlight}
        >
          <Text pb={1}>{t('helpContactSendButton')}</Text>
        </Button>
      </VStack>
    </>
  );
};

export default Component;
