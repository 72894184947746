/**
 * @generated SignedSource<<1cd8c83a066e3e0c11f4f109d5e6c5e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessAccountStatus = "ACTIVE" | "DELETED" | "NOT_CONFIGURED" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type ConfigureAccountQuery$variables = {
  token: string;
};
export type ConfigureAccountQuery$data = {
  readonly getBusinessAccount: {
    readonly accountStatus: BusinessAccountStatus;
    readonly id: string;
  } | null;
};
export type ConfigureAccountQuery = {
  variables: ConfigureAccountQuery$variables;
  response: ConfigureAccountQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "BusinessAccount",
    "kind": "LinkedField",
    "name": "getBusinessAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureAccountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfigureAccountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6fcf6c013e97d47c76b5fdd42b92fc2d",
    "id": null,
    "metadata": {},
    "name": "ConfigureAccountQuery",
    "operationKind": "query",
    "text": "query ConfigureAccountQuery(\n  $token: String!\n) {\n  getBusinessAccount(token: $token) {\n    accountStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f15f812b344f76f2a390c3b492f7a83";

export default node;
