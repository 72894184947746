import type {FC, ReactElement} from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import type {
  Dashboard_listOfCharges$data,
  BusinessPaymentStatus
} from 'components/__generated__/Dashboard_listOfCharges.graphql';
import type {FragmentType} from 'types';
import TransactionTable from 'components/TransactionTable';
import graphql from 'babel-plugin-relay/macro';
import {VStack, Box, Heading, HStack, Button} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {Routes} from 'types/enums';
import {partition} from 'ramda';

export const fragment = graphql`
  fragment Dashboard_listOfCharges on BusinessChargesConnection {
    edges {
      node {
        id
        expiresAt
        description
        createdAt
        confirmedAt
        paidAt
        metadata
        paymentStatus
        processStatus
        processedAt
        refundPaidAt
        refundRequestedAt
        userId
        products {
          title
          price
          quantity
          image
          description
        }
        pricing {
          fiat {
            amount
            currency
          }
          LTC {
            address
            amount
            currency
          }
          ETH {
            address
            amount
            currency
          }
          BTC {
            address
            amount
            currency
          }
        }
        transaction {
          amount
          confirmations
          currency
          id
          isInternal
        }
      }
    }
  }
`;

type Params = {charges: FragmentType<Dashboard_listOfCharges$data>};

const buildPartition = (
  conditions: BusinessPaymentStatus[],
  nodes: Params['charges']['edges']
) => {
  const transactions = nodes.map(({node}) => ({...node}));
  return partition<typeof transactions[number]>(
    (tx) => !conditions.includes(tx.paymentStatus)
  )(transactions);
};

const Component: FC<Params> = ({charges}): ReactElement => {
  const {t} = useTranslation();
  const [requested, refunds] = useMemo(
    () => buildPartition(['REFUNDED'], charges.edges),
    [charges]
  );
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('dashboardPageTitle')}</title>
      </Helmet>
      <VStack spacing={4}>
        <Box as="article" w="full">
          <HStack justify="space-between" py={2}>
            <Heading as="h4" fontSize="lg">
              {t('dashboardPaymentsRequested')}
            </Heading>
            <RouterLink to={Routes.CREATE_PAYMENT}>
              <Button size="sm" colorScheme="brand" as="span">
                {t('dashboardCreatePayment')}
              </Button>
            </RouterLink>
          </HStack>

          <TransactionTable charges={requested} />
        </Box>
        <Box as="article" w="full">
          <Heading as="h4" fontSize="lg" py={2}>
            {t('dashboardPaymentsRefunds')}
          </Heading>
          <TransactionTable charges={refunds} />
        </Box>
      </VStack>
    </>
  );
};

export default Component;
