import {Box, Spinner, Icon, Center, Container} from '@chakra-ui/react';
import {FC} from 'react';
import {ReactComponent as Logo} from 'assets/logo.svg';

interface Props {
  header?: boolean;
}
const Component: FC<Props> = ({header = false}) => {
  const boxProps = header
    ? {
        pt: 24,
        h: '100vh',
        bgColor: 'whitesmoke'
      }
    : {};
  return (
    <Box {...boxProps}>
      <Container mt={header ? 2 : 0}>
        <Center flexDirection="column">
          <Icon as={Logo} w="full" h="4xs" my={8} />
          <Spinner size="xl" color="brand.600" />
        </Center>
      </Container>
    </Box>
  );
};

export default Component;
