/**
 * @generated SignedSource<<9ee39729f0d0c9bb131b836306ec503c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BusinessPaymentStatus = "CONFIRMED" | "DELAYED" | "EXPIRED" | "PAID" | "PENDING" | "REFUNDED" | "UNDERPAID" | "%future added value";
export type FiatCurrencies = "EUR" | "%future added value";
export type BusinessProductInput = {
  description?: string | null;
  image?: string | null;
  price: number;
  quantity: number;
  title: string;
};
export type CreatePaymentMutation$variables = {
  token: string;
  amount: number;
  currency: FiatCurrencies;
  description: string;
  products: ReadonlyArray<BusinessProductInput>;
  continueUrl: string;
  cancelUrl: string;
  ttl: number;
};
export type CreatePaymentMutation$data = {
  readonly businessCreateCharge: {
    readonly node: {
      readonly createdAt: string;
      readonly description: string;
      readonly expiresAt: string;
      readonly id: string;
      readonly paymentStatus: BusinessPaymentStatus;
      readonly pricing: {
        readonly fiat: {
          readonly amount: string;
          readonly currency: FiatCurrencies;
        };
      };
      readonly products: ReadonlyArray<{
        readonly description: string | null;
        readonly image: string | null;
        readonly price: number;
        readonly quantity: number;
        readonly title: string;
      }>;
    } | null;
  };
};
export type CreatePaymentMutation = {
  variables: CreatePaymentMutation$variables;
  response: CreatePaymentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cancelUrl"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "continueUrl"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "products"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ttl"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "cancelUrl",
        "variableName": "cancelUrl"
      },
      {
        "kind": "Variable",
        "name": "continueUrl",
        "variableName": "continueUrl"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "products",
        "variableName": "products"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "ttl",
        "variableName": "ttl"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessCharge",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessPricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FiatCurrency",
          "kind": "LinkedField",
          "name": "fiat",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessProduct",
      "kind": "LinkedField",
      "name": "products",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "BusinessCreateChargePayload",
        "kind": "LinkedField",
        "name": "businessCreateCharge",
        "plural": false,
        "selections": [
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "BusinessCreateChargePayload",
        "kind": "LinkedField",
        "name": "businessCreateCharge",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5db73a2e96b0dfb597657cd1778429c6",
    "id": null,
    "metadata": {},
    "name": "CreatePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePaymentMutation(\n  $token: String!\n  $amount: Float!\n  $currency: FiatCurrencies!\n  $description: String!\n  $products: [BusinessProductInput!]!\n  $continueUrl: String!\n  $cancelUrl: String!\n  $ttl: Int!\n) {\n  businessCreateCharge(input: {token: $token, amount: $amount, currency: $currency, description: $description, products: $products, continueUrl: $continueUrl, cancelUrl: $cancelUrl, ttl: $ttl}) {\n    node {\n      createdAt\n      description\n      expiresAt\n      id\n      paymentStatus\n      pricing {\n        fiat {\n          amount\n          currency\n        }\n      }\n      products {\n        description\n        image\n        price\n        quantity\n        title\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9badeb8e8880e4a93cb90bf0f0c85e73";

export default node;
