import {createContext} from 'react';
import {Auth} from 'libs/Auth';

/**
 * Wrapper to handle promises errors:
 *
 * `const {error, result} = await withError(promise);`
 *
 * @param promise
 */
export const withError = async <T, R = Error>(
  promise: Promise<T>
): Promise<{error: R | undefined | unknown; result: T | undefined}> => {
  try {
    return {error: undefined, result: await promise};
  } catch (error) {
    return {
      error,
      result: undefined
    };
  }
};

/**
 * React Context used to provide a singleton for an authorization library (e.g: Firebase or Amplify/Cognito)
 */
export const AuthContext = createContext<InstanceType<typeof Auth> | undefined>(
  undefined
);
