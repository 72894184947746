/**
 * @generated SignedSource<<08edf9fa7128fcb8cb2feca7adcae641>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsComponent_account$data = {
  readonly apiKeyId: string | null;
  readonly notificationUrl: string | null;
  readonly id: string;
  readonly branding: {
    readonly accentColor: string | null;
    readonly bgColor: string | null;
    readonly logoUrl: string | null;
    readonly shopName: string | null;
  } | null;
  readonly cryptoHoldingPercentage: string | null;
  readonly " $fragmentType": "SettingsComponent_account";
};
export type SettingsComponent_account$key = {
  readonly " $data"?: SettingsComponent_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsComponent_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsComponent_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "apiKeyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAccountBranding",
      "kind": "LinkedField",
      "name": "branding",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accentColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bgColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shopName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cryptoHoldingPercentage",
      "storageKey": null
    }
  ],
  "type": "BusinessAccount",
  "abstractKey": null
};

(node as any).hash = "5a7d8f69c29fdfbf78644e9a945acee2";

export default node;
