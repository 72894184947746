/**
 * @generated SignedSource<<37d5846c5e0b3499e6f71e2d0eaf5dfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SettingsAccountMutation$variables = {
  token: string;
  id: string;
  shopName?: string | null;
  accentColor?: string | null;
  logoUrl?: string | null;
  cryptoHoldingPercentage?: string | null;
};
export type SettingsAccountMutation$data = {
  readonly businessUpdateAccount: {
    readonly node: {
      readonly id: string;
      readonly notificationUrl: string | null;
      readonly branding: {
        readonly accentColor: string | null;
        readonly bgColor: string | null;
        readonly logoUrl: string | null;
        readonly shopName: string | null;
      } | null;
      readonly cryptoHoldingPercentage: string | null;
    } | null;
  };
};
export type SettingsAccountMutation = {
  variables: SettingsAccountMutation$variables;
  response: SettingsAccountMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accentColor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cryptoHoldingPercentage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "logoUrl"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shopName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v6 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "accentColor",
            "variableName": "accentColor"
          },
          {
            "kind": "Variable",
            "name": "logoUrl",
            "variableName": "logoUrl"
          },
          {
            "kind": "Variable",
            "name": "shopName",
            "variableName": "shopName"
          }
        ],
        "kind": "ObjectValue",
        "name": "branding"
      },
      {
        "kind": "Variable",
        "name": "cryptoHoldingPercentage",
        "variableName": "cryptoHoldingPercentage"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessAccount",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAccountBranding",
      "kind": "LinkedField",
      "name": "branding",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accentColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bgColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shopName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cryptoHoldingPercentage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "BusinessUpdateAccountPayload",
        "kind": "LinkedField",
        "name": "businessUpdateAccount",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SettingsAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "BusinessUpdateAccountPayload",
        "kind": "LinkedField",
        "name": "businessUpdateAccount",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cfd4491e4d4dbbf7ac1ed42f0fb1c52",
    "id": null,
    "metadata": {},
    "name": "SettingsAccountMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsAccountMutation(\n  $token: String!\n  $id: ID!\n  $shopName: String\n  $accentColor: String\n  $logoUrl: String\n  $cryptoHoldingPercentage: String\n) {\n  businessUpdateAccount(input: {token: $token, id: $id, branding: {shopName: $shopName, logoUrl: $logoUrl, accentColor: $accentColor}, cryptoHoldingPercentage: $cryptoHoldingPercentage}) {\n    node {\n      id\n      notificationUrl\n      branding {\n        accentColor\n        bgColor\n        logoUrl\n        shopName\n      }\n      cryptoHoldingPercentage\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ed6b69899b7dd38c4d4edf586e67920";

export default node;
