import type {FC, ReactElement} from 'react';
import {useState, useRef, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import type {FragmentType} from 'types';
import {BsImage as ImageIcon} from 'react-icons/bs';
import type {SettingsComponent_account$data} from 'components/__generated__/SettingsComponent_account.graphql';
import type {SettingsApiKeyMutation$data} from 'components/__generated__/SettingsApiKeyMutation.graphql';
import ReactMarkdown from 'react-markdown';
import {useMutation} from 'react-relay';
import {AuthContext} from 'libs/utils';
import {equals, isNil, reject} from 'ramda';
import graphql from 'babel-plugin-relay/macro';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
  SimpleGrid,
  GridItem,
  InputRightElement,
  InputGroup,
  Icon,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogFooter,
  ButtonGroup,
  Text,
  useToast,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Switch
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {BlockPicker} from 'react-color';
import env from 'env-var';

export const fragment = graphql`
  fragment SettingsComponent_account on BusinessAccount {
    apiKeyId
    notificationUrl
    id
    branding {
      accentColor
      bgColor
      logoUrl
      shopName
    }
    cryptoHoldingPercentage
  }
`;

const apiKeyMutation = graphql`
  mutation SettingsApiKeyMutation($token: String!, $id: ID!) {
    businessCreateApiKey(input: {token: $token, id: $id}) {
      node {
        apiKeyId
      }
    }
  }
`;

const secretMutation = graphql`
  mutation SettingsSecretMutation(
    $token: String!
    $id: ID!
    $secret: String!
    $notificationUrl: String!
  ) {
    businessPutSecret(
      input: {
        token: $token
        id: $id
        secret: $secret
        notificationUrl: $notificationUrl
      }
    ) {
      node {
        id
      }
    }
  }
`;

const updateAccountMutation = graphql`
  mutation SettingsAccountMutation(
    $token: String!
    $id: ID!
    $shopName: String
    $accentColor: String
    $logoUrl: String
    $cryptoHoldingPercentage: String
  ) {
    businessUpdateAccount(
      input: {
        token: $token
        id: $id
        branding: {
          shopName: $shopName
          logoUrl: $logoUrl
          accentColor: $accentColor
        }
        cryptoHoldingPercentage: $cryptoHoldingPercentage
      }
    ) {
      node {
        id
        notificationUrl
        branding {
          accentColor
          bgColor
          logoUrl
          shopName
        }
        cryptoHoldingPercentage
      }
    }
  }
`;

const {brandColor} = {
  brandColor: env.get('REACT_APP_BRAND_COLOR').required().asString()
};

type SettingsParams = {account: FragmentType<SettingsComponent_account$data>};
type SettingsFormData = Pick<
  SettingsParams['account'],
  'notificationUrl' | 'cryptoHoldingPercentage'
> &
  SettingsParams['account']['branding'];

type SecurityParams = {
  accountId: string;
};

type SecurityFormData = {
  secret: string;
  notificationUrl: string;
};

const Security: FC<SecurityParams> = ({accountId}) => {
  const {t} = useTranslation();
  const toast = useToast();
  const cancelApiRequestRef = useRef(null);
  const closeApiKeyRef = useRef(null);
  const auth = useContext(AuthContext);
  const [requestApiKey, setRequestApiKey] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);
  const [isOpenSecretModal, setIsOpenSecretModal] = useState<boolean>(false);
  const [showApiKey, setShowApiKey] = useState<boolean>(false);
  const {register, handleSubmit} = useForm<SecurityFormData>();
  const [apiKeyMutationCommit, apiKeyMutationIsInFlight] =
    useMutation(apiKeyMutation);

  const [secretMutationCommit, secretMutationIsInFlight] =
    useMutation(secretMutation);

  const onSubmit = async (data: SecurityFormData) => {
    if (!auth) throw new Error("Auth isn't initialized");
    const token = await auth?.getToken();
    secretMutationCommit({
      variables: {
        token,
        id: accountId,
        secret: data.secret,
        notificationUrl: data.notificationUrl
      },
      onCompleted: (response, error) => {
        console.log(response);
        if (error) {
          toast({
            title: t('error.putSecretError'),
            status: 'error',
            isClosable: true
          });
          return;
        }
        setIsOpenSecretModal(false);
      }
    });
  };

  const requestKey = async () => {
    if (!auth) throw new Error("Auth isn't initialized");
    const token = await auth?.getToken();
    apiKeyMutationCommit({
      variables: {
        token,
        id: accountId
      },
      onCompleted: (response, error) => {
        if (error) {
          toast({
            title: t('errors.requestKeyError'),
            status: 'error',
            isClosable: true
          });
          return;
        }
        const {businessCreateApiKey} = response as SettingsApiKeyMutation$data;
        setApiKey(businessCreateApiKey.node?.apiKeyId ?? undefined);
        setShowApiKey(true);
        setRequestApiKey(false);
      }
    });
  };
  return (
    <Box py={4}>
      <Heading
        as="h3"
        fontSize="lg"
        fontWeight="semibold"
        borderBottomWidth="thin"
        pb={1}
      >
        {t('settingsSecuritySectionTitle')}
      </Heading>
      <VStack py={2} wrap="wrap" alignItems="start" fontSize="sm">
        <Box>
          <Text py={2}>{t('requestApiKeyText')}</Text>
          <Button
            type="button"
            colorScheme="brand"
            size="sm"
            onClick={() => setRequestApiKey(!requestApiKey)}
          >
            {t('requestApiKeyButton')}
          </Button>
        </Box>
        <Box>
          <Text py={2}>{t('updateSecretText')}</Text>
          <Button
            type="button"
            colorScheme="brand"
            size="sm"
            onClick={() => setIsOpenSecretModal(true)}
          >
            {t('updateSecretButton')}
          </Button>
        </Box>
      </VStack>

      <AlertDialog
        isOpen={requestApiKey}
        onClose={() => setRequestApiKey(false)}
        leastDestructiveRef={cancelApiRequestRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('apiKeyRequestChangeHeader')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <ReactMarkdown children={t('apiKeyRequestChangeNotice')} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelApiRequestRef}
                  onClick={() => setRequestApiKey(false)}
                >
                  {t('cancelRequestOfApiKey')}
                </Button>
                <Button
                  colorScheme="brand"
                  isLoading={apiKeyMutationIsInFlight}
                  onClick={requestKey}
                >
                  {t('confirmRequestOfApiKey')}
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={showApiKey}
        onClose={() => {
          setShowApiKey(false);
          setApiKey(undefined);
        }}
        leastDestructiveRef={closeApiKeyRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('showApiKeyHeader')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <ReactMarkdown children={t('showApiKeyNotice', {key: apiKey})} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={closeApiKeyRef}
                onClick={() => {
                  setShowApiKey(false);
                  setApiKey(undefined);
                }}
              >
                {t('showApiKeyCloseButton')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        isOpen={isOpenSecretModal}
        onClose={() => setIsOpenSecretModal(false)}
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{t('settingsSecuritySecretHeader')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} w="full" alignItems="stretch">
              <Text>{t('settingsSecuritySecretBody')}</Text>
              <FormControl id="notificationUrl" mb={2}>
                <FormLabel textColor="gray.900">
                  {t('settingsSecurityNotificationUrl')}
                </FormLabel>
                <Input
                  type="url"
                  focusBorderColor="brand.600"
                  bgColor="whitesmoke"
                  placeholder={t('settingsSecurityNotificationUrlPlaceholder')}
                  {...register('notificationUrl')}
                />
              </FormControl>
              <FormControl id="secret" mb={2}>
                <FormLabel textColor="gray.900">
                  {t('settingsSecuritySecret')}
                </FormLabel>
                <Input
                  type="string"
                  focusBorderColor="brand.600"
                  bgColor="whitesmoke"
                  placeholder={t('settingsSecuritySecretPlaceholder')}
                  {...register('secret')}
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              type="submit"
              isLoading={secretMutationIsInFlight}
            >
              {t('updateSecretButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const Component: FC<SettingsParams> = ({account}): ReactElement => {
  const initialBrandColor = account.branding?.accentColor ?? brandColor;
  const {t} = useTranslation();
  const [color, setColor] = useState<string>(initialBrandColor);
  const auth = useContext(AuthContext);
  const toast = useToast();
  console.log(account);
  const defaultValues = useMemo(
    () => ({
      notificationUrl: account.notificationUrl,
      logoUrl: account.branding?.logoUrl,
      accentColor: initialBrandColor,
      shopName: account.branding?.shopName,
      cryptoHoldingPercentage: account.cryptoHoldingPercentage
    }),
    [account, initialBrandColor]
  );
  const {register, watch, handleSubmit, setValue} = useForm<SettingsFormData>({
    defaultValues
  });
  const watchedValues = watch();
  const hasChangedInputs = useMemo(() => {
    const removeNulls = reject(isNil);
    const watchedWithColor = {...watchedValues, accentColor: color};
    return !equals(removeNulls(defaultValues), removeNulls(watchedWithColor));
  }, [defaultValues, watchedValues, color]);
  const imageSrc = watch('logoUrl') as string | undefined;
  const isCryptoHoldingPercentageChecked =
    (watch('cryptoHoldingPercentage') as string | undefined) === '100';
  const [updateAccountMutationCommit, updateAccountMutationIsInFlight] =
    useMutation(updateAccountMutation);

  const updateAccount = async (data: SettingsFormData) => {
    if (!auth) throw new Error("Auth isn't initialized");
    const token = await auth?.getToken();
    const variables = {
      token,
      id: account.id,
      shopName: data.shopName,
      accentColor: color,
      logoUrl: data.logoUrl,
      cryptoHoldingPercentage: data.cryptoHoldingPercentage
    };
    updateAccountMutationCommit({
      variables,
      onCompleted: (response) => {
        if (response)
          toast({
            title: t('messages.updateUserSuccess'),
            status: 'success',
            isClosable: true
          });
      },
      onError: () => {
        toast({
          title: t('errors.updateUserError'),
          status: 'error',
          isClosable: true
        });
      }
    });
  };
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('settingsPageTitle')}</title>
      </Helmet>
      <VStack
        as="form"
        alignItems="stretch"
        spacing={4}
        onSubmit={handleSubmit(updateAccount)}
      >
        <VStack alignItems="stretch" spacing={6}>
          <Heading
            as="h3"
            fontSize="lg"
            fontWeight="semibold"
            borderBottomWidth="thin"
            pb={1}
          >
            {t('settingsBrandingSectionTitle')}
          </Heading>
          <FormControl id="shopName" maxW="md">
            <FormLabel textColor="gray.900">
              {t('settingsCompanyName')}
            </FormLabel>
            <Input
              autoComplete="organization"
              type="text"
              focusBorderColor="brand.600"
              bgColor="whitesmoke"
              placeholder={t('settingsCompanyNamePlaceholder')}
              {...register('shopName')}
            />
          </FormControl>
          <SimpleGrid columns={{base: 1, md: 4}} maxW="md" spacing={2}>
            <GridItem colSpan={4} rowStart={1}>
              <FormLabel textColor="gray.900" htmlFor="logoUrl">
                {t('settingsBrandingLogoUrl')}
              </FormLabel>
            </GridItem>
            <GridItem colSpan={1} rowStart={{base: 3, md: 2}}>
              <Image
                src={imageSrc}
                fallbackSrc="https://via.placeholder.com/150x150.png"
              />
            </GridItem>
            <GridItem flexDirection="column" colSpan={3} rowStart={2}>
              <FormControl id="logoUrl" mb={2}>
                <InputGroup>
                  <Input
                    autoComplete="photo"
                    type="url"
                    focusBorderColor="brand.600"
                    bgColor="whitesmoke"
                    placeholder={t('settingsBrandingLogoUrlPlaceholder')}
                    {...register('logoUrl')}
                  />
                  <InputRightElement children={<Icon as={ImageIcon} />} />
                </InputGroup>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <Box>
            <Heading as="h4" fontSize="md" fontWeight="medium" pb={2}>
              {t('settingsBrandingColor')}
            </Heading>
            <BlockPicker
              color={color}
              onChangeComplete={(color) => setColor(color.hex)}
              triangle="hide"
            />
          </Box>
          <SimpleGrid columns={{base: 1, md: 4}} maxW="md" spacing={2}>
            <GridItem colSpan={4} rowStart={1}>
              <FormLabel textColor="gray.900" htmlFor="logoUrl">
                {t('settingsHoldCripto')}
              </FormLabel>
            </GridItem>
            <GridItem flexDirection="column" colSpan={3} rowStart={2}>
              <FormControl id="cryptoHoldingPercentage" mb={2}>
                <Switch
                  size="md"
                  bgColor="whitesmoke"
                  isChecked={isCryptoHoldingPercentageChecked}
                  onChange={({target}) => {
                    setValue(
                      'cryptoHoldingPercentage',
                      target.checked ? '100' : '0'
                    );
                  }}
                />
              </FormControl>
            </GridItem>
          </SimpleGrid>
          <HStack justify="flex-end">
            <Button
              colorScheme="brand"
              size="sm"
              isDisabled={!hasChangedInputs}
              isLoading={updateAccountMutationIsInFlight}
              type="submit"
            >
              {t('settingsUpdateUser')}
            </Button>
          </HStack>
        </VStack>
      </VStack>
      <Security accountId={account.id} />
    </>
  );
};

export default Component;
