import {ColorModeScript} from '@chakra-ui/react';
import {StrictMode, Suspense} from 'react';
import {hydrate, render} from 'react-dom';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {Amplify} from 'aws-amplify';
import {BrowserRouter as Router} from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import {AuthContext} from 'libs/utils';
import Auth from 'libs/Auth';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import {ErrorBoundary} from 'react-error-boundary';
import RelayEnvironment from 'RelayEnvironment';
import ErrorFallback from 'components/ErrorFallback';
import SuspenseFallback from 'components/SuspenseFallback';
import App from 'components/App';
import {HelmetProvider} from 'react-helmet-async';
import {RelayEnvironmentProvider} from 'react-relay';
import {datadogRum} from '@datadog/browser-rum';
import {datadogRumConfig, awsConfig, colors} from 'config';

Amplify.configure(awsConfig);
const theme = extendTheme({colors});
datadogRum.init(datadogRumConfig);

const rootElement = document.getElementById('root');
const app = (
  <StrictMode>
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <Suspense fallback={<SuspenseFallback header />}>
          <Router>
            <HelmetProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
              <I18nextProvider i18n={i18n}>
                <AuthContext.Provider value={Auth}>
                  <App />
                </AuthContext.Provider>
                </I18nextProvider>
              </ErrorBoundary>
            </HelmetProvider>
          </Router>
        </Suspense>
      </ChakraProvider>
    </RelayEnvironmentProvider>
  </StrictMode>
);

rootElement?.hasChildNodes()
  ? hydrate(app, rootElement)
  : render(app, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
