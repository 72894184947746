import {
  Box,
  Heading,
  Icon,
  Text,
  Container,
  Code,
  Button
} from '@chakra-ui/react';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Logo} from 'assets/logo.svg';
import {FallbackProps} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import {Errors} from '@criptan/types';
import auth from 'libs/Auth';

const DEFAULT_ERROR = 'errorBoundary';

const generateErrorCode = (type: string) => {
  switch (type) {
    case Errors.noVerfiedAccount.code:
      return `errors.noVerfiedAccount`;
    case Errors.noBusinessAccount.code:
      return `errors.noBusinessAccount`;
    default:
      return 'errorBoundary';
  }
};
const Component: FC<FallbackProps> = ({error}) => {
  const {t} = useTranslation();
  let errorKey = DEFAULT_ERROR;
  try {
    // @ts-expect-error because error is not what is coming on ts
    const errorKeyMessage = JSON.parse(error.errors[0].message);
    errorKey = generateErrorCode(errorKeyMessage.code);
  } catch (err) {
    console.error(err);
  }
  const handleSignOut = () => {
    auth.signOut().finally(() => {
      window.location.href = '/login';
    });
  };
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t(`${errorKey}PageTitle`)}</title>
      </Helmet>
      <Box as="main">
        <Container centerContent px={4}>
          <Icon as={Logo} w={{base: 'sm', md: 'md'}} h="4xs" />
          <Container style={{textAlign: 'center'}} py={20}>
            <Heading as="h1" my={2}>
              {t(`${errorKey}Title`)}
            </Heading>
            <Text>{t(`${errorKey}Message`)}</Text>
          </Container>
          {error.message && (
            <Code w="full" py={2} colorScheme="red" my={2}>
              {error.message}
            </Code>
          )}
        </Container>
        <Container centerContent>
          <Button variant="solid" colorScheme="brand" onClick={handleSignOut}>
            {t('logout')}
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default Component;
