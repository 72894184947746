import BigNumber from 'bignumber.js';

export const formatCurrency = (
  locale: string,
  currency: string,
  amount: string,
  minimumFractionDigits: 2 | 4 | 6 | 8
) => {
  if (!currency || !amount) return '-------';

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits: 8
  }).format(new BigNumber(amount).toString() as unknown as number);
};
