/**
 * @generated SignedSource<<b7c3f01b6e5067bae1ff398d523f653f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PaymentInformationGenerateReceiptMutation$variables = {
  token: string;
  id: string;
  lng?: string | null;
};
export type PaymentInformationGenerateReceiptMutation$data = {
  readonly businessGenerateReceipt: {
    readonly node: {
      readonly url: string;
    } | null;
  };
};
export type PaymentInformationGenerateReceiptMutation = {
  variables: PaymentInformationGenerateReceiptMutation$variables;
  response: PaymentInformationGenerateReceiptMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lng"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "lng",
        "variableName": "lng"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentInformationGenerateReceiptMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BusinessGenerateReceiptPayload",
        "kind": "LinkedField",
        "name": "businessGenerateReceipt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessReceipt",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PaymentInformationGenerateReceiptMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BusinessGenerateReceiptPayload",
        "kind": "LinkedField",
        "name": "businessGenerateReceipt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessReceipt",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5f169389d408b466a23e571eda1eaa0",
    "id": null,
    "metadata": {},
    "name": "PaymentInformationGenerateReceiptMutation",
    "operationKind": "mutation",
    "text": "mutation PaymentInformationGenerateReceiptMutation(\n  $token: String!\n  $id: ID!\n  $lng: String\n) {\n  businessGenerateReceipt(input: {token: $token, id: $id, lng: $lng}) {\n    node {\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "35c68220cceb8ebe5b831ee4034f8224";

export default node;
