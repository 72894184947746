/**
 * @generated SignedSource<<3177c1dffce2c73e5d5ac5307951a2b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessAccountStatus = "ACTIVE" | "DELETED" | "NOT_CONFIGURED" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type BusinessPaymentStatus = "CONFIRMED" | "DELAYED" | "EXPIRED" | "PAID" | "PENDING" | "REFUNDED" | "UNDERPAID" | "%future added value";
export type BusinessProcessStatus = "ERROR" | "INITIATED" | "PROCESSED" | "UNPROCESSED" | "%future added value";
export type CryptoCurrencies = "BTC" | "ETH" | "LTC" | "USDC" | "%future added value";
export type Currencies = "BTC" | "ETH" | "EUR" | "LTC" | "USDC" | "%future added value";
export type FiatCurrencies = "EUR" | "%future added value";
export type MainUserQuery$variables = {
  token: string;
};
export type MainUserQuery$data = {
  readonly getBusinessAccount: {
    readonly accountStatus: BusinessAccountStatus;
    readonly apiKeyId: string | null;
    readonly userId: string;
    readonly notificationUrl: string | null;
    readonly cryptoHoldingPercentage: string | null;
    readonly id: string;
    readonly branding: {
      readonly accentColor: string | null;
      readonly bgColor: string | null;
      readonly logoUrl: string | null;
      readonly shopName: string | null;
    } | null;
    readonly deletedAt: string | null;
  } | null;
  readonly getBusinessCharges: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly expiresAt: string;
        readonly description: string;
        readonly createdAt: string;
        readonly confirmedAt: any | null;
        readonly paidAt: any | null;
        readonly metadata: any | null;
        readonly paymentStatus: BusinessPaymentStatus;
        readonly processStatus: BusinessProcessStatus;
        readonly processedAt: any | null;
        readonly refundPaidAt: any | null;
        readonly refundRequestedAt: any | null;
        readonly userId: string;
        readonly products: ReadonlyArray<{
          readonly title: string;
          readonly price: number;
          readonly quantity: number;
          readonly image: string | null;
          readonly description: string | null;
        }>;
        readonly pricing: {
          readonly fiat: {
            readonly amount: string;
            readonly currency: FiatCurrencies;
          };
          readonly LTC: {
            readonly address: string;
            readonly amount: string;
            readonly currency: Currencies;
          };
          readonly ETH: {
            readonly address: string;
            readonly amount: string;
            readonly currency: Currencies;
          };
          readonly BTC: {
            readonly address: string;
            readonly amount: string;
            readonly currency: Currencies;
          };
        };
        readonly transaction: {
          readonly amount: string;
          readonly confirmations: number;
          readonly currency: CryptoCurrencies;
          readonly id: string;
          readonly isInternal: boolean;
        } | null;
      };
    }>;
  } | null;
};
export type MainUserQuery = {
  variables: MainUserQuery$variables;
  response: MainUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "BusinessAccount",
    "kind": "LinkedField",
    "name": "getBusinessAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apiKeyId",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notificationUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cryptoHoldingPercentage",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessAccountBranding",
        "kind": "LinkedField",
        "name": "branding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accentColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bgColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shopName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "BusinessChargesConnection",
    "kind": "LinkedField",
    "name": "getBusinessCharges",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessChargeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessCharge",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiresAt",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "confirmedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paidAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadata",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "processStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "processedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refundPaidAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refundRequestedAt",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessProduct",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPricing",
                "kind": "LinkedField",
                "name": "pricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FiatCurrency",
                    "kind": "LinkedField",
                    "name": "fiat",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BusinessPricingOptionCrypto",
                    "kind": "LinkedField",
                    "name": "LTC",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BusinessPricingOptionCrypto",
                    "kind": "LinkedField",
                    "name": "ETH",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BusinessPricingOptionCrypto",
                    "kind": "LinkedField",
                    "name": "BTC",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessTransaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "confirmations",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInternal",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MainUserQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MainUserQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "5ef4068d148200e6117f589dc43842b9",
    "id": null,
    "metadata": {},
    "name": "MainUserQuery",
    "operationKind": "query",
    "text": "query MainUserQuery(\n  $token: String!\n) {\n  getBusinessAccount(token: $token) {\n    accountStatus\n    apiKeyId\n    userId\n    notificationUrl\n    cryptoHoldingPercentage\n    id\n    branding {\n      accentColor\n      bgColor\n      logoUrl\n      shopName\n    }\n    deletedAt\n  }\n  getBusinessCharges(token: $token) {\n    edges {\n      node {\n        id\n        expiresAt\n        description\n        createdAt\n        confirmedAt\n        paidAt\n        metadata\n        paymentStatus\n        processStatus\n        processedAt\n        refundPaidAt\n        refundRequestedAt\n        userId\n        products {\n          title\n          price\n          quantity\n          image\n          description\n        }\n        pricing {\n          fiat {\n            amount\n            currency\n          }\n          LTC {\n            address\n            amount\n            currency\n          }\n          ETH {\n            address\n            amount\n            currency\n          }\n          BTC {\n            address\n            amount\n            currency\n          }\n        }\n        transaction {\n          amount\n          confirmations\n          currency\n          id\n          isInternal\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bad3f3ec06dd4c12f9923191b5854e5c";

export default node;
