/**
 * @generated SignedSource<<2a09ab6fa9574926da3ea1e5305ff64a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BusinessPaymentStatus = "CONFIRMED" | "DELAYED" | "EXPIRED" | "PAID" | "PENDING" | "REFUNDED" | "UNDERPAID" | "%future added value";
export type BusinessProcessStatus = "ERROR" | "INITIATED" | "PROCESSED" | "UNPROCESSED" | "%future added value";
export type CryptoCurrencies = "BTC" | "ETH" | "LTC" | "USDC" | "%future added value";
export type Currencies = "BTC" | "ETH" | "EUR" | "LTC" | "USDC" | "%future added value";
export type FiatCurrencies = "EUR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Dashboard_listOfCharges$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly expiresAt: string;
      readonly description: string;
      readonly createdAt: string;
      readonly confirmedAt: any | null;
      readonly paidAt: any | null;
      readonly metadata: any | null;
      readonly paymentStatus: BusinessPaymentStatus;
      readonly processStatus: BusinessProcessStatus;
      readonly processedAt: any | null;
      readonly refundPaidAt: any | null;
      readonly refundRequestedAt: any | null;
      readonly userId: string;
      readonly products: ReadonlyArray<{
        readonly title: string;
        readonly price: number;
        readonly quantity: number;
        readonly image: string | null;
        readonly description: string | null;
      }>;
      readonly pricing: {
        readonly fiat: {
          readonly amount: string;
          readonly currency: FiatCurrencies;
        };
        readonly LTC: {
          readonly address: string;
          readonly amount: string;
          readonly currency: Currencies;
        };
        readonly ETH: {
          readonly address: string;
          readonly amount: string;
          readonly currency: Currencies;
        };
        readonly BTC: {
          readonly address: string;
          readonly amount: string;
          readonly currency: Currencies;
        };
      };
      readonly transaction: {
        readonly amount: string;
        readonly confirmations: number;
        readonly currency: CryptoCurrencies;
        readonly id: string;
        readonly isInternal: boolean;
      } | null;
    };
  }>;
  readonly " $fragmentType": "Dashboard_listOfCharges";
};
export type Dashboard_listOfCharges$key = {
  readonly " $data"?: Dashboard_listOfCharges$data;
  readonly " $fragmentSpreads": FragmentRefs<"Dashboard_listOfCharges">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Dashboard_listOfCharges",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessChargeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BusinessCharge",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expiresAt",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "metadata",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "processStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "processedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refundPaidAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refundRequestedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BusinessProduct",
              "kind": "LinkedField",
              "name": "products",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BusinessPricing",
              "kind": "LinkedField",
              "name": "pricing",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FiatCurrency",
                  "kind": "LinkedField",
                  "name": "fiat",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BusinessPricingOptionCrypto",
                  "kind": "LinkedField",
                  "name": "LTC",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BusinessPricingOptionCrypto",
                  "kind": "LinkedField",
                  "name": "ETH",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BusinessPricingOptionCrypto",
                  "kind": "LinkedField",
                  "name": "BTC",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BusinessTransaction",
              "kind": "LinkedField",
              "name": "transaction",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "confirmations",
                  "storageKey": null
                },
                (v3/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isInternal",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BusinessChargesConnection",
  "abstractKey": null
};
})();

(node as any).hash = "52da46e776e1c20246041e08fe99040c";

export default node;
