import type {FC, ReactElement} from 'react';
import {useMemo, useContext} from 'react';
import type {TFunction} from 'react-i18next';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-relay';
import {Helmet} from 'react-helmet-async';
import {AuthContext} from 'libs/utils';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  Button,
  Table,
  Tbody,
  Thead,
  Box,
  // Tfoot,
  Tr,
  Td,
  Th,
  Text,
  Center,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  ButtonGroup,
  HStack,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack
} from '@chakra-ui/react';
import {useHistory} from 'react-router-dom';
import type {Row, Column} from 'react-table';
import {useTable} from 'react-table';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import graphql from 'babel-plugin-relay/macro';
import type {
  CreatePaymentMutation$variables,
  CreatePaymentMutation$data
} from 'components/__generated__/CreatePaymentMutation.graphql';
import type {Dashboard_listOfCharges$data} from 'components/__generated__/Dashboard_listOfCharges.graphql';
import type {FragmentType} from 'types';
import {Routes} from 'types/enums';
import BigNumber from 'bignumber.js';
import {BiTimer} from 'react-icons/bi';
import {Errors} from '@criptan/types';

const groupName = 'products';

type Params = {charges: FragmentType<Dashboard_listOfCharges$data>};
type Item = Partial<
  Params['charges']['edges'][number]['node']['products'][number]
>;
type FormData = {
  description: string;
  continueUrl: string;
  cancelUrl: string;
  [groupName]: Item[];
  ttl: number;
};

const mutation = graphql`
  mutation CreatePaymentMutation(
    $token: String!
    $amount: Float!
    $currency: FiatCurrencies!
    $description: String!
    $products: [BusinessProductInput!]!
    $continueUrl: String!
    $cancelUrl: String!
    $ttl: Int!
  ) {
    businessCreateCharge(
      input: {
        token: $token
        amount: $amount
        currency: $currency
        description: $description
        products: $products
        continueUrl: $continueUrl
        cancelUrl: $cancelUrl
        ttl: $ttl
      }
    ) {
      node {
        createdAt
        description
        expiresAt
        id
        paymentStatus
        pricing {
          fiat {
            amount
            currency
          }
        }
        products {
          description
          image
          price
          quantity
          title
        }
      }
    }
  }
`;

const defaultColumns = (
  t: TFunction,
  register: ReturnType<typeof useForm>['register']
): Column<Item>[] => {
  return [
    {
      Header: t('createPaymentTitleHeader') as string,
      accessor: 'title',
      Cell: ({row}) => {
        return (
          <FormControl isRequired id={`title-${row.index}`}>
            <Input
              size="sm"
              defaultValue=""
              focusBorderColor="brand.600"
              placeholder={t('createPaymentTitleHeader') as string}
              {...register(`${groupName}[${row.id}][title]`)}
            />
          </FormControl>
        );
      }
    },
    {
      Header: t('createPaymentDescriptionHeader') as string,
      accessor: 'description',
      Cell: ({row}: {row: Row<Item>}) => {
        return (
          <FormControl isRequired id={`description-${row.index}`}>
            <Input
              size="sm"
              defaultValue=""
              focusBorderColor="brand.600"
              placeholder={t('createPaymentDescriptionHeader') as string}
              {...register(`${groupName}[${row.id}][description]`)}
            />
          </FormControl>
        );
      }
    },
    {
      Header: t('createPaymentQuantityHeader') as string,
      accessor: 'quantity',
      Cell: ({row}: {row: Row<Item>}) => {
        return (
          <FormControl isRequired id={`quantity-${row.index}`}>
            <NumberInput
              focusBorderColor="brand.600"
              size="sm"
              defaultValue={1}
            >
              <NumberInputField
                {...register(`${groupName}[${row.id}][quantity]`, {
                  valueAsNumber: true,
                  min: 1
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        );
      }
    },
    {
      Header: t('createPaymentPriceHeader') as string,
      accessor: 'price',
      Cell: ({row}: {row: Row<Item>}) => {
        return (
          <FormControl isRequired id={`price-${row.index}`}>
            <NumberInput
              focusBorderColor="brand.600"
              size="sm"
              defaultValue={1}
            >
              <NumberInputField
                {...register(`${groupName}[${row.id}][price]`, {
                  valueAsNumber: true,
                  min: 1
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        );
      }
    },
    {
      Header: t('createPaymentImageHeader') as string,
      accessor: 'image',
      Cell: ({row}: {row: Row<Item>}) => {
        return (
          <FormControl id={`image-${row.index}`}>
            <Input
              type="url"
              size="md"
              defaultValue=""
              focusBorderColor="brand.600"
              placeholder="https://..."
              {...register(`${groupName}[${row.id}][image]`)}
            />
          </FormControl>
        );
      }
    }
  ];
};

const Component: FC<{onComplete: null | (() => void)}> = ({
  onComplete
}): ReactElement => {
  const {
    t
    // i18n
  } = useTranslation();
  const {
    register,
    handleSubmit,
    // watch,
    formState,
    control
  } = useForm();
  const {fields, append, remove} = useFieldArray<Item>({
    //@ts-expect-error: seems like a bug in the lib
    name: groupName,
    control
  });
  const [commit, isInFlight] = useMutation(mutation);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const toast = useToast();
  const columns = useMemo(() => defaultColumns(t, register), [t, register]);
  const {getTableProps, getTableBodyProps, rows, headers, prepareRow} =
    useTable({
      columns,
      // @ts-expect-error
      data: fields
    });

  const onSubmit = async (data: FormData) => {
    const amount = data[groupName].reduce(
      (total, curr) =>
        curr.quantity && curr.price
          ? new BigNumber(curr.price)
              .times(new BigNumber(curr.quantity))
              .plus(new BigNumber(total))
              .toNumber()
          : total,
      0
    );
    if (!auth) throw new Error("Auth hasn't been initialized");
    const token = await auth.getToken();
    const variables: CreatePaymentMutation$variables = {
      description: data.description,
      //@ts-expect-error: types are incorrect as we can't selectively make require properties requires
      products: Object.values(data[groupName]),
      token,
      currency: 'EUR',
      amount,
      cancelUrl: data.cancelUrl,
      continueUrl: data.continueUrl,
      ttl: data.ttl
    };
    commit({
      variables,
      onCompleted: (response) => {
        const {businessCreateCharge} = response as CreatePaymentMutation$data;
        toast({
          title: t('messages.newChargeCreated'),
          status: 'success',
          isClosable: true
        });
        onComplete && onComplete();
        history.push(`${Routes.PAYMENT}/${businessCreateCharge.node?.id}`);
      },
      // @ts-expect-error wrong typing
      onError: (error: {errors: {message: string}[]}) => {
        const {errors = []} = error;
        const errorKey = `errors.${
          errors.length > 0 &&
          errors[0].message &&
          errors[0].message.includes(Errors.exceedRestrictedChargeAmount.code)
            ? Errors.exceedRestrictedChargeAmount.code
            : 'chargeCreatedError'
        }`;
        toast({
          title: t(errorKey, 'errors.chargeCreatedError'),
          status: 'error',
          isClosable: true
        });
      }
    });
  };

  // @ts-expect-error
  const submit = handleSubmit(onSubmit);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>{t('createPaymentPageTitle')}</title>
      </Helmet>
      <VStack as="form" spacing={4} onSubmit={submit}>
        <FormControl isRequired>
          <FormLabel>{t('createPaymentDescription')}</FormLabel>
          <Input
            bgColor="whitesmoke"
            focusBorderColor="brand.600"
            {...register('description')}
          />
        </FormControl>
        <HStack
          w="full"
          flexDirection={{base: 'column', md: 'row'}}
          spacing={2}
        >
          <FormControl isRequired>
            <FormLabel>{t('createPaymentContinueUrl')}</FormLabel>
            <Input
              type="url"
              bgColor="whitesmoke"
              focusBorderColor="brand.600"
              {...register('continueUrl')}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>{t('createPaymentCancelUrl')}</FormLabel>
            <Input
              type="url"
              bgColor="whitesmoke"
              focusBorderColor="brand.600"
              {...register('cancelUrl')}
            />
          </FormControl>
        </HStack>
        <FormControl isRequired>
          <Controller
            name="ttl"
            control={control}
            defaultValue={30}
            rules={{required: true}}
            render={({field}) => (
              <>
                <FormLabel>
                  {t('createPaymentExpiryTime', {expiryTime: field.value})}
                </FormLabel>
                <Slider
                  min={5}
                  max={60}
                  step={5}
                  focusThumbOnChange={false}
                  {...field}
                >
                  <SliderTrack bg="brand.600">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg="tomato" />
                  </SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Box color="brand.600" as={BiTimer} />
                  </SliderThumb>
                </Slider>
              </>
            )}
          />
        </FormControl>
        <Box overflow="auto" w="full">
          <Table variant="unstyled" size="sm" {...getTableProps()}>
            <Thead>
              <Tr>
                {headers.map((header) => (
                  <Th
                    textColor="gray.700"
                    fontSize="xs"
                    fontWeight="light"
                    px={4}
                    py={1}
                    borderTopWidth="thin"
                    borderBottomWidth="thin"
                    {...header.getHeaderProps()}
                  >
                    <Text as="span">
                      {
                        // Render the header. We force the string type as we aren't going to put anything else there
                        t(header.render('Header') as string)
                      }
                    </Text>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                  </Tr>
                );
              })}
              <Tr>
                <Td colSpan={4}>
                  <Center>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        variant="outline"
                        isDisabled={isInFlight}
                        onClick={() => {
                          append({
                            //@ts-expect-error: seems like a bug in the lib
                            title: '',
                            price: 1,
                            quantity: 1,
                            description: ''
                          });
                        }}
                        type="button"
                      >
                        {t('createPaymentAddRow')}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline"
                        isDisabled={fields.length < 1 || isInFlight}
                        onClick={() => remove(-1)}
                        type="button"
                      >
                        {t('createPaymentRemoveRow')}
                      </Button>
                    </ButtonGroup>
                  </Center>
                </Td>
              </Tr>
            </Tbody>
            {/* <Tfoot>
            <Tr>
              Need this ugly hack so the price is aligned
              <Td colSpan={2}></Td>
              <Td textColor="gray.700" fontSize="sm" fontWeight="light">
                {t('createPaymentFooterPrice')}
              </Td>
              <Td textColor="gray.700" fontSize="sm" fontWeight="light">
                {Intl.NumberFormat(i18n.languages, {
                  style: 'currency',
                  currency: 'EUR'
                }).format(totalPrice)}
              </Td>
            </Tr>
          </Tfoot> */}
          </Table>
        </Box>
        <Button
          colorScheme="brand"
          size="sm"
          type="submit"
          isLoading={isInFlight}
          disabled={!formState.isValid || fields.length === 0}
        >
          {t('createPaymentSubmitNew')}
        </Button>
      </VStack>
    </>
  );
};

export default Component;
