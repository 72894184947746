/**
 * @generated SignedSource<<4e172ffa4cb550ea753ce24cf76855f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessPaymentStatus = "CONFIRMED" | "DELAYED" | "EXPIRED" | "PAID" | "PENDING" | "REFUNDED" | "UNDERPAID" | "%future added value";
export type BusinessProcessStatus = "ERROR" | "INITIATED" | "PROCESSED" | "UNPROCESSED" | "%future added value";
export type CryptoCurrencies = "BTC" | "ETH" | "LTC" | "USDC" | "%future added value";
export type Currencies = "BTC" | "ETH" | "EUR" | "LTC" | "USDC" | "%future added value";
export type FiatCurrencies = "EUR" | "%future added value";
export type PaymentInformationUserQuery$variables = {
  token: string;
  id: string;
};
export type PaymentInformationUserQuery$data = {
  readonly getBusinessCharge: {
    readonly id: string;
    readonly expiresAt: string;
    readonly description: string;
    readonly createdAt: string;
    readonly confirmedAt: any | null;
    readonly paidAt: any | null;
    readonly metadata: any | null;
    readonly paymentStatus: BusinessPaymentStatus;
    readonly processStatus: BusinessProcessStatus;
    readonly processedAt: any | null;
    readonly refundPaidAt: any | null;
    readonly refundRequestedAt: any | null;
    readonly userId: string;
    readonly checkoutUrl: string;
    readonly products: ReadonlyArray<{
      readonly title: string;
      readonly price: number;
      readonly quantity: number;
      readonly image: string | null;
      readonly description: string | null;
    }>;
    readonly pricing: {
      readonly fiat: {
        readonly amount: string;
        readonly currency: FiatCurrencies;
      };
      readonly LTC: {
        readonly address: string;
        readonly amount: string;
        readonly currency: Currencies;
      };
      readonly ETH: {
        readonly address: string;
        readonly amount: string;
        readonly currency: Currencies;
      };
      readonly BTC: {
        readonly address: string;
        readonly amount: string;
        readonly currency: Currencies;
      };
    };
    readonly transaction: {
      readonly amount: string;
      readonly confirmations: number;
      readonly currency: CryptoCurrencies;
      readonly id: string;
      readonly isInternal: boolean;
    } | null;
  } | null;
};
export type PaymentInformationUserQuery = {
  variables: PaymentInformationUserQuery$variables;
  response: PaymentInformationUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "BusinessCharge",
    "kind": "LinkedField",
    "name": "getBusinessCharge",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiresAt",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confirmedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paidAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "processStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "processedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refundPaidAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refundRequestedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkoutUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessProduct",
        "kind": "LinkedField",
        "name": "products",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessPricing",
        "kind": "LinkedField",
        "name": "pricing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FiatCurrency",
            "kind": "LinkedField",
            "name": "fiat",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPricingOptionCrypto",
            "kind": "LinkedField",
            "name": "LTC",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPricingOptionCrypto",
            "kind": "LinkedField",
            "name": "ETH",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPricingOptionCrypto",
            "kind": "LinkedField",
            "name": "BTC",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessTransaction",
        "kind": "LinkedField",
        "name": "transaction",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmations",
            "storageKey": null
          },
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInternal",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentInformationUserQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PaymentInformationUserQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "7bde9a5b3721f0266ce319ccc373e624",
    "id": null,
    "metadata": {},
    "name": "PaymentInformationUserQuery",
    "operationKind": "query",
    "text": "query PaymentInformationUserQuery(\n  $token: String!\n  $id: ID!\n) {\n  getBusinessCharge(token: $token, id: $id) {\n    id\n    expiresAt\n    description\n    createdAt\n    confirmedAt\n    paidAt\n    metadata\n    paymentStatus\n    processStatus\n    processedAt\n    refundPaidAt\n    refundRequestedAt\n    userId\n    checkoutUrl\n    products {\n      title\n      price\n      quantity\n      image\n      description\n    }\n    pricing {\n      fiat {\n        amount\n        currency\n      }\n      LTC {\n        address\n        amount\n        currency\n      }\n      ETH {\n        address\n        amount\n        currency\n      }\n      BTC {\n        address\n        amount\n        currency\n      }\n    }\n    transaction {\n      amount\n      confirmations\n      currency\n      id\n      isInternal\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4030731502c426fa0bd958939f345505";

export default node;
