import env from 'env-var';

const {brandColor, aws_appsync_graphqlEndpoint, aws_appsync_apiKey} = {
  brandColor: env.get('REACT_APP_BRAND_COLOR').required().asString(),
  aws_appsync_graphqlEndpoint: env
    .get('REACT_APP_API_URL')
    .required()
    .asUrlString(),
  aws_appsync_apiKey: env.get('REACT_APP_API_KEY').required().asString()
};

export const colors = {
  brand: {
    600: brandColor,
    500: brandColor
  }
};

export const awsConfig = {
  aws_appsync_graphqlEndpoint,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey
};

export const datadogRumConfig = {
  applicationId: env
    .get('REACT_APP_DATADOG_APPLICATION_ID')
    .required()
    .asString(),
  clientToken: env.get('REACT_APP_DATADOG_CLIENT_TOKEN').required().asString(),
  site: env.get('REACT_APP_DATADOG_SITE').required().asString(),
  // service: 'client-admin',
  // env: 'production',
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true
};

export const cryptoRegex = {
  BTC: /([13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})/,
  ETH: /0x[a-fA-F0-9]{40}/,
  // @todo add proper validation
  USDC: /0x[a-fA-F0-9]{40}/,
  LTC: /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/
};
