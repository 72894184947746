export enum Routes {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  PAYMENTS = '/dashboard/payments',
  PAYMENT = '/dashboard/payment',
  CREATE_PAYMENT = '/dashboard/payment/create',
  SETTINGS = '/dashboard/settings',
  CREATE_ACCOUNT = '/dashboard/settings/create',
  LOGIN = '/login',
  HELP = '/dashboard/help'
}
