import {useMemo} from 'react';
import type {FC, ReactElement} from 'react';
import {nameof} from 'types';
import type {Column} from 'react-table';
import {useTable, usePagination, useSortBy, useFilters} from 'react-table';
import {useTranslation} from 'react-i18next';
import type {TFunction} from 'react-i18next';
import {
  GoChevronRight as ChevronRight,
  GoChevronLeft as ChevronLeft,
  GoChevronDown as ChevronDown,
  GoChevronUp as ChevronUp
} from 'react-icons/go';
import ReactMarkdown from 'react-markdown';
import type {Dashboard_listOfCharges$data} from 'components/__generated__/Dashboard_listOfCharges.graphql';
import type {FragmentType} from 'types';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Badge,
  Button,
  ButtonGroup,
  VisuallyHidden,
  Icon,
  HStack,
  Box,
  Link
} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import BigNumber from 'bignumber.js';
import {Routes} from 'types/enums';
import {pascalCase} from 'change-case';

type Node = FragmentType<Dashboard_listOfCharges$data>['edges'][number]['node'];
type Params = {
  charges: Node[];
};

const defaultHeaders = (
  locale: string,
  tFunction: TFunction
): Column<Node>[] => [
  {
    Header: 'transactionTableId',
    accessor: 'id',
    disableSortBy: true,
    Cell: ({value}) => (
      <Link as={RouterLink} to={`${Routes.PAYMENT}/${value}`} fontSize="xs">
        {value}
      </Link>
    )
  },
  {
    Header: 'transactionTableDescription',
    disableSortBy: true,
    accessor: 'description',
    Cell: ({value}) => (
      <Text isTruncated maxW="3xs" fontSize="xs" textColor="gray.600">
        {value}
      </Text>
    )
  },
  {
    Header: 'transactionTablePrice',
    accessor: 'pricing',
    sortType: (rowA, rowB) => {
      const a = new BigNumber((rowA.values as Node).pricing.fiat.amount);
      const b = new BigNumber((rowB.values as Node).pricing.fiat.amount);
      if (a.isGreaterThan(b)) return -1;
      else if (a.isLessThan(b)) return 1;
      else return 0;
    },
    Cell: ({value}) => {
      const {fiat} = value;
      return (
        <Text>
          {Intl.NumberFormat(locale, {
            style: 'currency',
            currency: fiat.currency
          }).format(new BigNumber(fiat.amount).toString() as unknown as number)}
        </Text>
      );
    }
  },
  {
    Header: 'transactionTableReceived',
    accessor: 'transaction',
    disableSortBy: true,
    Cell: ({value}) => {
      if (!value) return <Text></Text>;
      return (
        <Text fontSize="xs" textColor="gray.600">
          {Intl.NumberFormat(locale, {
            style: 'currency',
            currency: value.currency,
            maximumFractionDigits: 18
          }).format(
            new BigNumber(value.amount).toString() as unknown as number
          )}
        </Text>
      );
    }
  },
  {
    Header: 'transactionTableDate',
    accessor: 'createdAt',
    Cell: ({value}) => new Intl.DateTimeFormat(locale).format(new Date(value))
  },
  {
    Header: 'transactionTableStatus',
    accessor: 'paymentStatus',
    Cell: ({value}) => {
      let schema = 'gray';
      switch (value) {
        case 'CONFIRMED':
          schema = 'green';
          break;
        case 'PAID':
          schema = 'green';
          break;
        case 'PENDING':
          schema = 'blue';
          break;
        case 'DELAYED':
          schema = 'yellow';
          break;
        case 'UNDERPAID':
          schema = 'yellow';
          break;
        case 'REFUNDED':
          schema = 'green';
          break;
        default:
          break;
      }
      return (
        <Badge fontSize="xs" variant="solid" colorScheme={schema}>
          {tFunction(`transactionTableStatus${pascalCase(value)}`, value)}
        </Badge>
      );
    }
  }
];

const Component: FC<Params> = ({charges}): ReactElement => {
  const {t, i18n} = useTranslation();
  const [locale] = i18n.languages;
  const columns = useMemo(() => defaultHeaders(locale, t), [locale, t]);
  const data = useMemo(() => charges, [charges]);
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: useMemo(() => [{id: nameof<Node>('createdAt'), desc: true}], [])
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const totalTransactions = charges.length;
  const {pageIndex, pageSize} = state;
  const fromIndex = 1 + pageIndex * pageSize;
  const toIndex = fromIndex + page.length - 1;

  return (
    <>
      <Box overflow="auto" w="full" h="sm">
        <Table {...getTableProps()} variant="unstyled" size="sm">
          <Thead>
            <Tr>
              {headers.map((header) => (
                <Th
                  textColor="gray.700"
                  fontSize="xs"
                  fontWeight="light"
                  px={4}
                  py={1}
                  borderTopWidth="thin"
                  borderBottomWidth="thin"
                  {...header.getHeaderProps(header.getSortByToggleProps())}
                >
                  <Text as="span">
                    {
                      // Render the header. We force the string type as we aren't going to put anything else there
                      t(header.render('Header') as string)
                    }
                  </Text>
                  {header.canSort && header.isSorted ? (
                    <Icon as={header.isSortedDesc ? ChevronDown : ChevronUp} />
                  ) : null}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <HStack justify="space-between" py={2} px={4} w="full">
        <Box fontSize="sm" textColor="gray.700">
          {totalTransactions > 0 ? (
            <ReactMarkdown
              children={t('transactionTablePaginationResults', {
                from: fromIndex,
                to: toIndex,
                total: totalTransactions
              })}
            />
          ) : (
            ''
          )}
        </Box>

        <ButtonGroup isAttached as="nav">
          <Button
            size="xs"
            onClick={previousPage}
            isDisabled={!canPreviousPage}
          >
            <Icon as={ChevronLeft} />
            <VisuallyHidden>{t('transactionTablePrevious')}</VisuallyHidden>
          </Button>
          {pageOptions.map((index) => (
            <Button
              size="xs"
              key={index.toString()}
              bgColor={index === pageIndex ? 'gray.100' : 'white'}
              onClick={() => gotoPage(index)}
            >
              {index + 1}
            </Button>
          ))}
          <Button size="xs" onClick={nextPage} isDisabled={!canNextPage}>
            <Icon as={ChevronRight} />
            <VisuallyHidden>{t('transactionTableNext')}</VisuallyHidden>
          </Button>
        </ButtonGroup>
      </HStack>
    </>
  );
};

export default Component;
