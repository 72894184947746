import {
  Environment,
  Network,
  Store,
  Observable,
  RecordSource
} from 'relay-runtime';
import type {
  SubscribeFunction,
  FetchFunction,
  RequestParameters
} from 'relay-runtime';
import {API, graphqlOperation} from 'aws-amplify';
import Auth from 'libs/Auth';

//@ts-expect-error: bad types
const fetchQuery: FetchFunction = async (
  operation: RequestParameters,
  variables?: Record<string, unknown>
) => {
  const authVars: Record<string, string> = {};
  if (variables?.token) {
    authVars.token = await Auth.getToken();
  }
  return API.graphql(
    graphqlOperation(operation.text, {...variables, ...authVars})
  );
};
const subscribe: SubscribeFunction = (
  operation: RequestParameters,
  variables?: Record<string, unknown>
) => {
  return Observable.from(
    //@ts-expect-error: bad types
    API.graphql(graphqlOperation(operation.text, variables)).map(
      //@ts-expect-error: bad types
      ({value}) => value
    )
  );
};
// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
  network: Network.create(fetchQuery, subscribe),
  store: new Store(new RecordSource())
});
