import type {FC, ReactElement} from 'react';
import type {ConfigureAccountQuery} from 'components/__generated__/ConfigureAccountQuery.graphql';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {Helmet} from 'react-helmet-async';
import {useContext, useEffect, useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import SuspenseFallback from 'components/SuspenseFallback';
import {BsImage as ImageIcon} from 'react-icons/bs';
import {
  SimpleGrid,
  Heading,
  Text,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
  Button,
  InputGroup,
  InputRightElement,
  Icon,
  useToast
} from '@chakra-ui/react';
import {Routes} from 'types/enums';
import {AuthContext} from 'libs/utils';
import {useMutation, useRelayEnvironment} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {QueryRenderer} from 'react-relay';

// @todo this should be a query to the global state, not to the server!
const query = graphql`
  query ConfigureAccountQuery($token: String!) {
    getBusinessAccount(token: $token) {
      accountStatus
      id
    }
  }
`;

const mutation = graphql`
  mutation ConfigureAccountMutation(
    $token: String!
    $id: ID!
    $logoUrl: String
    $shopName: String!
  ) {
    businessUpdateAccount(
      input: {
        token: $token
        id: $id
        branding: {shopName: $shopName, logoUrl: $logoUrl}
      }
    ) {
      node {
        branding {
          shopName
          logoUrl
        }
      }
    }
  }
`;

interface IFormData {
  logoUrl?: string;
  shopName: string;
}

const Component: FC = (): ReactElement => {
  const {t} = useTranslation();
  const history = useHistory();
  const [token, setToken] = useState<string | undefined>();
  const [account, setAccount] = useState<string | undefined>();
  const auth = useContext(AuthContext);
  const [commit, isInFlight] = useMutation(mutation);
  const {register, handleSubmit, watch} = useForm<IFormData>();
  const toast = useToast();
  const imageSrc = watch('logoUrl');
  useEffect(() => {
    const getToken = async () =>
      auth?.getCurrentUser()
        ? setToken(await auth?.getToken())
        : setToken(undefined);
    getToken();
  }, [auth]);
  const onSubmit = async (data: IFormData) => {
    const variables = {
      logoUrl: data.logoUrl,
      shopName: data.shopName,
      token: await auth?.getToken(),
      id: account
    };
    commit({
      variables,
      onCompleted: () => {
        toast({
          title: t('messages.userDataUpdated'),
          status: 'success',
          isClosable: true
        });
        history.push(Routes.PAYMENTS);
      },
      onError: () => {
        toast({
          title: t('errors.updateUserError'),
          status: 'error',
          isClosable: true
        });
      }
    });
  };
  const environment = useRelayEnvironment();
  if (!token) return <SuspenseFallback />;
  return (
    <QueryRenderer<ConfigureAccountQuery>
      environment={environment}
      query={query}
      variables={{
        token
      }}
      render={({error, props}) => {
        if (error) throw error;
        if (!props) return <SuspenseFallback />;
        if (props.getBusinessAccount?.accountStatus !== 'NOT_CONFIGURED')
          <Redirect to={Routes.PAYMENTS} />;
        setAccount(props.getBusinessAccount?.id);
        return (
          <>
            <Helmet>
              <meta name="robots" content="noindex" />
              <title>{t('createAccountPageTitle')}</title>
            </Helmet>
            <SimpleGrid
              as="form"
              columns={{base: 1, md: 3}}
              onSubmit={handleSubmit(onSubmit)}
              spacing={4}
            >
              <GridItem as="article">
                <Heading
                  as="h3"
                  fontSize="lg"
                  fontWeight="medium"
                  textColor="gray.900"
                >
                  {t('createAccountInfoTitle')}
                </Heading>
                <Text textColor="gray.600" fontSize="sm">
                  {t('createAccountInfoText')}
                </Text>
              </GridItem>
              <GridItem
                colSpan={2}
                bgColor="white"
                p={8}
                shadow="lg"
                rounded="lg"
              >
                <VStack spacing={4}>
                  <FormControl isRequired id="shopName">
                    <FormLabel textColor="gray.900">
                      {t('createAccountShopName')}
                    </FormLabel>
                    <Input
                      autoComplete="organization"
                      type="text"
                      focusBorderColor="brand.600"
                      bgColor="whitesmoke"
                      placeholder={t('createAccountShopNamePlaceholder')}
                      {...register('shopName')}
                    />
                  </FormControl>
                  <SimpleGrid columns={4} spacing={4} w="full">
                    <GridItem colSpan={4} rowStart={1}>
                      <FormLabel textColor="gray.900" htmlFor="logoUrl">
                        {t('createAccountLogoUrl')}
                      </FormLabel>
                    </GridItem>
                    <GridItem colSpan={1} rowStart={{base: 3, md: 2}}>
                      <Image
                        src={imageSrc}
                        fallbackSrc="https://via.placeholder.com/150.png"
                      />
                    </GridItem>
                    <GridItem
                      colSpan={{base: 4, md: 3}}
                      rowStart={2}
                      flexDirection="column"
                    >
                      <FormControl id="logoUrl" mb={2}>
                        <InputGroup>
                          <Input
                            autoComplete="photo"
                            type="url"
                            focusBorderColor="brand.600"
                            bgColor="whitesmoke"
                            placeholder={t('createAccountLogoUrlPlaceholder')}
                            {...register('logoUrl')}
                          />
                          <InputRightElement
                            children={<Icon as={ImageIcon} />}
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                  </SimpleGrid>
                  <Button
                    colorScheme="brand"
                    type="submit"
                    isLoading={isInFlight}
                    w="full"
                    maxWidth="sm"
                    my={2}
                  >
                    {t('createAccountSubmit')}
                  </Button>
                </VStack>
              </GridItem>
            </SimpleGrid>
          </>
        );
      }}
    />
  );
};

export default Component;
