import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import env from 'env-var';

const config = {
  apiKey: env.get('REACT_APP_FIREBASE_API_KEY').required().asString(),
  authDomain: env.get('REACT_APP_FIREBASE_AUTH_DOMAIN').required().asString(),
  projectId: env.get('REACT_APP_FIREBASE_AUTH_DOMAIN').required().asString(),
  appId: env.get('REACT_APP_FIREBASE_APP_ID').required().asString()
};

type listener = (ready: boolean) => void;

export class Auth {
  private readonly auth: ReturnType<typeof app.auth>;
  private ready: boolean = false;
  private readonly listeners: listener[] = [];
  constructor() {
    this.auth = app.initializeApp(config).auth();
    this.auth.onAuthStateChanged((a) => {
      if (!this.ready) {
        this.ready = true;
        this.notifyAuthState();
      }
    });
  }

  notifyAuthState = (): void => {
    this.listeners.forEach((callback) => {
      callback(true);
    });
  };
  subscribeToAuthState = (callback: listener): void => {
    this.listeners.push(callback);
    if (this.ready) {
      callback(true);
    }
  };
  logUser = async (email: string, password: string): Promise<string> => {
    const auth = await this.auth.signInWithEmailAndPassword(email, password);
    // user will exist IF auth is correct. Otherwhise, it will throw
    return (await auth.user?.getIdToken()) as string;
  };
  getToken = async (): Promise<string> => {
    const user = this.auth.currentUser;
    if (!user) throw new Error("User doesn't exist");
    return user.getIdToken();
  };
  signOut = (): Promise<void> => this.auth.signOut();
  getCurrentUser = (): app.User | null => this.auth.currentUser;
}

export default new Auth();
